import React, { useEffect, useState } from "react";
import MomentUtils from "@date-io/moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Container,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import CloseIcon from "@material-ui/icons/Close";

import * as spillActions from "../../../../../actionCreators/Spill";

import SalvageContainer from "./SalvageContainer";
import {
  Constants,
  getAttachmentTypes,
  photoTypes,
  getAttachmentsTypeCount,
  roundOff,
  getPublicIp,
  ROLES,
  convertServicesObj,
  orderAlphabatically,
  isPESUser,
  timeConvertMinsToHHMM,
  timeConvertHHMMtoMins,
} from "../../../../../utils";
import { EditNoteText as Text } from "../../../../../utils/language/english";

import {
  Prompt,
  WholeScreenModal,
  NoteAttachmentTable,
  TextBox,
  CustomSnackBar,
} from "../../../../../Components";
import { CustomSelect } from "../../../../../Components/MultiLvlSelect";
import AreaCoveredModal from "./AreaCoveredModal";
import Alert from "@material-ui/lab/Alert";
import { delay, isPdfValid } from "../../../../../utils/helper";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: green[500],
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  attachmentImage: {
    margin: "1rem",
    border: "1px solid black",
    display: "flex",
  },
  alignRight: {
    textAlign: "right",
    paddingRight: "20px",
  },
  topMargin: {
    marginTop: "0px",
  },
  formControl: {
    minWidth: "150px",
    margin: "10px 5px 0 5px",
  },
  tableHead: {
    backgroundColor: "#eaeaea",
  },
  tableHeadText: {
    color: "#6ea16c",
    fontWeight: 800,
  },
}));

const EditNote = ({
  currentUser,
  closeNoteFrom,
  submitNote,
  Notes,
  spillRate,
  currentNote,
  editNotes,
  disabled,
  currentSpill,
  setUpdateSpillNote,
  checkSelectedServiceHandler,
  setOpenAreaCoverModal,
  openAreaCoverModal,
  editNoteState,
  noteWorkTimeValidation,
  noteWorkStartTimeValidation,
  executeScroll,
  noteWorkTimeValidationReset
}) => {
  const classes = useStyles();

  const [selectedService, setSelectedService] = React.useState();
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [filesList, setFilesList] = React.useState([]);
  const [containers, setContainers] = React.useState(
    currentNote?.salvage_containers ?? []
  );
  const [attachmentTypesCount, setAttachmentTypesCount] = React.useState(null);
  const [fileType, setFileType] = React.useState("");
  const [miscType, setMiscType] = React.useState("");
  const [customPhotoType, setCustomPhotoType] = React.useState("");
  const [photoType, setPhotoType] = React.useState("");
  const [showEmailModal, setShowEmailModal] = React.useState(false);
  const [initialRender, setInitialRender] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState({
    message: "",
    severity: "",
    open: false,
  });

  const [note, setNote] = React.useState({
    ...currentNote,
    id: currentNote.id,
    spillId: currentNote.spill_id,
    userId: currentNote.user_id,
    serviceId: currentNote.service_id || null,
    serviceType: currentNote.service_type || null,
    rate: currentNote.rate || null,
    hour: currentNote.hour || null,
    amount: currentNote.amount || null,
    description: currentNote.description || null,
    reportNo: currentNote.report_no || null,
    ipAddressIdentifier: currentNote.ip_address_identifier || null,
    owner: currentNote.owner || null,
    samples: currentNote.samplesno_of_samples || null,
    cost: currentNote.estimated_cost || null,
    establishLaneClosure: currentNote.established_lane_closure,
    excavationBegun: currentNote.excavation_begun,
    excavationCompleted: currentNote.excavation_completed,
    date: currentNote?.date || null,
    time: currentNote?.time || null,
    taskAssociatedRelevance: currentNote.task_associated_relevance || null,
    attachments: currentNote.note_attachments || [],
    type: currentNote.type || "",
    projected_eta: currentNote?.projected_eta
      ? timeConvertMinsToHHMM(currentNote?.projected_eta)
      : null,
    actual_eta: currentNote?.actual_eta
      ? timeConvertMinsToHHMM(currentNote?.actual_eta)
      : null,
    releaseCoverages: currentNote?.release_coverages || null,
    work_start_time: currentNote?.work_start_time,
  });
  const [startTime, setStartTime] = useState(
    currentNote?.work_start_time
      ? new Date(currentNote.work_start_time)
      : null
  );
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [startTimeCopy, setStartTimeCopy] = useState(null);
  const [isNoteSubmit, setIsNoteSubmit] = useState(true)
  const [resonseEmail, setResonseEmail] = useState(false)
  const [isButtonDisable, setIsButtonDisabled] = React.useState(false)
  const isNoteTimeWorkValidation = currentSpill?.data?.spill?.client_organization?.note_work_time_validation ?? false
  const [releaseCoverages, SetReleaseCoverages] = React.useState(
    currentNote.release_coverages ?? []
  );
  const [deletedNoteIds, setDeletedNoteIds] = useState([]);
  const handleRemoveLocationData = (indexToRemove, coverageId) => {
    const updatedCoverages = releaseCoverages.filter((_, index) => index !== indexToRemove);
    if (coverageId) {
      setDeletedNoteIds([...deletedNoteIds, coverageId]);
    }
    SetReleaseCoverages(updatedCoverages);
  };
  const handleAreaClickOpen = () => {
    setOpenAreaCoverModal(true);
  };
  const handleAreaClose = () => {
    setOpenAreaCoverModal(false);
  };

  const StartDateTime = async (value) => {
    setErrorMessage(false)
    const selectedDateTime = value?._d?.getTime();
    // if the calender work start time is set to empty consider the system geenrated time based on hour
    if (!selectedDateTime) {
      const currentTime = new Date();
      const systemGenerateTime = new Date(currentTime.getTime() - note?.hour * 60 * 60 * 1000);
      setStartTime(null)
      setStartTimeCopy(moment(systemGenerateTime))
    } else if (selectedDateTime <= new Date().getTime()) {
      setStartTime(value);
      setStartTimeCopy(value)
    }
  };

  React.useEffect(() => {
    if (note.serviceId) {
      const [service] = Notes.filter(
        (service) => service.id === note.serviceId
      );

      if (note.attachments) {
        let NoteAttachments = [...note.attachments];
        NoteAttachments = NoteAttachments.map((attachment) => {
          return { ...attachment, isDelete: false };
        });
        setNote((val) => {
          return {
            ...val,
            attachments: NoteAttachments,
          };
        });
      }
      initialRender &&
        setNote((val) => {
          return {
            ...val,
            rate: service?.type
              ? service?.rate
              : currentSpill?.data?.spill?.client_organization?.rate,
            hour: service?.type === "fixed" ? 0 : val?.hour,
            type: service?.type,
            amount:
              (service?.type
                ? service?.rate
                : currentSpill?.data?.spill?.client_organization?.rate) *
              (service?.type === "fixed" ? 1 : val.hour),
          };
        });

      const serviceObject = {
        ...service,
        rate: service?.type ? service.rate || spillRate : spillRate,
        type: service?.type || "hourly",
      };
      checkSelectedServiceHandler(serviceObject);

      setSelectedService(service);
    } else {
      setSelectedService("");
    }
    !initialRender && setInitialRender(true);
  }, [note.serviceId]);

  React.useEffect(() => {
    const attachedFilesNameList = [];
    currentSpill?.data?.spill?.spill_notes?.map((spillNote) =>
      spillNote?.note_attachments?.map((attachment) => {
        attachedFilesNameList.push(attachment.name);
      })
    );
    setFilesList([...attachedFilesNameList]);

    setAttachmentTypesCount(getAttachmentsTypeCount(currentSpill));
  }, [currentSpill]);

  useEffect(() => {
    setIsNoteSubmit(true);
    setErrorMessage(false);
    if (noteWorkStartTimeValidation?.error?.jobNo && selectedService?.type === "hourly") {
      const errorMesageToAppend = noteWorkStartTimeValidation?.error?.work_conflict ? "Please enter a later work start time in order to create the note." : "Please enter a lesser hour entry in order to create the note.";
      setIsNoteSubmit(false);
      setErrorMessage(`The selected time conflicts with job number ${noteWorkStartTimeValidation?.error?.jobNo}  and note ID ${noteWorkStartTimeValidation?.error?.noteId}, ${errorMesageToAppend}`);
      setIsButtonDisabled(false);
    }
  }, [noteWorkStartTimeValidation?.error, selectedService]);


  useEffect(() => {
    if (!isNoteSubmit) {
      executeScroll()
    } else if (isNoteSubmit && noteWorkStartTimeValidation?.success) {
      handleSubmitNote(resonseEmail);
    }
  }, [isNoteSubmit, noteWorkStartTimeValidation?.success])


  // useEffect(() => {
  //   setIsButtonDisabled(noteWorkStartTimeValidation?.loading ?? false)
  // }, [noteWorkStartTimeValidation?.loading])

  const callvalidateNoteTime = (workStartDateTime) => {
    if (!validationCheck(workStartDateTime) && note?.work_start_time) {
      const objectPayload = {
        work_start_time: moment(workStartDateTime)?.utc()?.format() || null,
        hour: note?.hour,
        user_id: currentSpill?.data?.spill?.user_id || null,
        org_id: currentSpill?.data?.spill?.org_id || null,
        note_id: note?.id
      };
      noteWorkTimeValidation(objectPayload);
      setIsNoteSubmit(true);
    }
  }

  const handleNoteChange = (key) => (event) => {
    let fieldVal = event.target.value;
    if (
      key === "rate" ||
      key === "amount" ||
      key === "hour" ||
      key === "samples" ||
      key === "cost"
    ) {
      if (key === "hour" && fieldVal === ".") fieldVal = 0 + ".";
      if (isNaN(fieldVal)) return;
    }
    setNote((val) => ({ ...val, [key]: fieldVal }));

    if (key === "rate" && note.hour) {
      setNote((val) => ({
        ...val,
        amount: roundOff(note.hour * note.type === "fixed" ? 1 : fieldVal, 2),
        rate: roundOff(fieldVal, 2),
      }));
    }

    if (key === "hour" && note.rate) {
      setNote((val) => ({
        ...val,
        amount: roundOff(note.rate * (+fieldVal).toFixed(2), 2),
        hour: roundOff(fieldVal, 2),
      }));
    }
  };

  const handleSubmitNote = async (sendEmail) => {
    const ipAddress = await getPublicIp();
    const formData = new FormData();
    
    if (note.type === "Custom Note") {
      if (!note.description) {
        return;
      }

      const formData = new FormData();

      selectedFiles.forEach((file) => {
        formData.append("file", file, `note#${file.name}`);
      });

      formData.append("ipAddress", JSON.stringify(ipAddress));
      formData.append(
        "note",
        JSON.stringify({
          ...note,
          sendEmail: sendEmail ? currentSpill.data.spill.user.email : null,
        })
      );

      editNotes(formData);
      noteWorkTimeValidationReset();
      closeNoteFrom();
    } else {
      const { serviceId, rate, amount, description } = note;
      const keysToCheck = {
        serviceId: {
          message: "Service is required",
          value: serviceId,
        },
        rate: {
          message: "Rate is required",
          value: rate,
        },
        amount: {
          message: "Amount is required",
          value: amount,
        },
        description: {
          message: "Description is required",
          value: description,
        },
      };
      if (!serviceId || !rate || !(amount || amount === 0) || !description) {
        for (let key in keysToCheck) {
          if (!keysToCheck[key].value) {
            setSnackbar({
              message: keysToCheck[key].message,
              severity: "warning",
              open: true,
            });
            break;
          }
        }
        return;
      }

      let { attachments } = note;

      if (attachments.length > 0 && fileType) {
        attachments = [
          ...attachments.map((attachment) => ({
            ...attachment,
            type: fileType,
          })),
        ];
      }

      const completeNote = {
        ...note,
        ...(attachments.length > 0 && { note_attachments: attachments }),
        containers: containers,
        serviceType: selectedService?.name,
        projected_eta: selectedService?.projected_eta
          ? timeConvertHHMMtoMins(note?.projected_eta)
          : null,
        actual_eta: selectedService?.actual_eta
          ? timeConvertHHMMtoMins(note?.actual_eta)
          : null,
        rate: parseFloat(note.rate)?.toFixed(2),
        amount: parseFloat(note.amount)?.toFixed(2),
        type: note.type ?? "hourly",
        coverageAreaData: releaseCoverages.filter(item => !item.id),
        deleted_coverage_areas: deletedNoteIds,
        work_start_time: startTime ? moment(startTime || startTimeCopy).utc().format() : null,
      };

      selectedFiles.forEach((file) => {
        formData.append("file", file, `note#${file.name}`);
      });

      formData.append(
        "note",
        JSON.stringify({
          ...completeNote,
          sendEmail: sendEmail ? currentSpill.data.spill.user.email : null,
        })
      );
      formData.append("ipAddress", JSON.stringify(ipAddress));

      editNotes(formData);
      setUpdateSpillNote(true);
      noteWorkTimeValidationReset();
      closeNoteFrom();
    }
  }

  function validationCheck(workStartTime) {
    setErrorMessage(false);
    const spillOpenedOn = currentSpill?.data?.spill?.opened_on;
    const noteStartTime = moment(workStartTime)?.format();
    if (noteStartTime < spillOpenedOn) {
      setErrorMessage("You can not select note's start time before the spill's open time.");
      setIsNoteSubmit(false);
      setIsButtonDisabled(false);
      return true;;
    }
    const expected_end_time = moment(workStartTime).add(note?.hour * 60, "minutes")?.format();
    if (new Date(expected_end_time) > new Date()) {
      setErrorMessage(`The end time you picked is in the future. Please enter a lesser hour entry in order to edit the note.`);
      setIsNoteSubmit(false);
      setIsButtonDisabled(false);
      return true;
    }
    return false;
  }

  const emailHandler = () => {
    setIsButtonDisabled(true)
    selectedService?.email_notifications ? setShowEmailModal(true) : handleSubmit(false);
  };

  const handleSubmit = async (sendEmail) => {

    // if the date is invalid then dont allow to edit the note
    if(note?.date && moment(!note?.date)?._isValid) {
      return;
    }

    // calculate the system generated time again when creating note that will be consider as a actual work start time
    let systemGeneratedTime = null;
    if (!startTime || !startTimeCopy) {
      const timeWhenCreateNote = new Date();
      systemGeneratedTime = new Date(timeWhenCreateNote.getTime() - note?.hour * 60 * 60 * 1000);
      setStartTimeCopy(systemGeneratedTime);
    }
    if (isNoteTimeWorkValidation && selectedService && selectedService?.type === "hourly" && note?.work_start_time) {
      callvalidateNoteTime(startTime ?? systemGeneratedTime);
    } else {
      handleSubmitNote(sendEmail);
    }

  }
  const updateFileType = (type) => {
    switch (type) {
      case "Photo":
        return photoType;
      case "Custom":
        return customPhotoType;
      default:
        return type;
    }
  };

  const onFileChangeHandler = async (event) => {
    setErrorMessage(false);

    let tempFiles = [...selectedFiles];
    let type = "";
    let fileName = "";
  
    const includeIncidentNo = [
      "Response Authorization",
      "Response Authorization Email",
      "Invoice & Report Package",
    ];
  
    // TODO: check for the file name logging and update it with numbers
    const uniqueAttachment = [
      "Situational Analysis",
      "Invoice & Report Package",
    ];
  
    const tempFilesList = filesList;
    let errorFiles = []; // To collect names of corrupted PDF files
  
    for (let file of event?.target?.files) {
      type = updateFileType(fileType);
  
      attachmentTypesCount[type]++;
  
      let ext = file.name.split(".");
      ext = ext[ext.length - 1];
  
      fileName = `${includeIncidentNo.includes(type)
          ? `${currentSpill.data.spill.job_no} `
          : ""
        }${type}${uniqueAttachment.includes(type)
          ? `${type === "Invoice & Report Package"
          ? `-${attachmentTypesCount[type]}`
            : ""
          }`
          : `-${attachmentTypesCount[type]}`
      }.${ext}`;
  
      // Validate PDF file
      const isPDF = ext.toLowerCase() === "pdf";

      if (isPDF && !await isPdfValid(file)) {
        errorFiles.push(file.name); // Add corrupted PDF file name to error list
        continue; // Skip adding this file to the tempFiles array
      }
  
      fileName = fileName.trimStart();
  
      let updatedFileName;
      if (
        (miscType && miscType !== "") ||
        (customPhotoType && customPhotoType !== "")
      ) {
        const parts = file?.name?.split(".");
        let newFileName = `${miscType || customPhotoType}.${parts[parts.length - 1]}`;
  
        newFileName = newFileName?.split(".")[0];
  
        if (tempFilesList.find((file) => file === newFileName)) {
          const totalFiles = tempFilesList.filter((file) =>
            file.includes(miscType || customPhotoType)
          );
  
          newFileName = `${newFileName}-${totalFiles.length + 1}`;
  
          updatedFileName = `${newFileName}.${parts[parts.length - 1]}`;
          tempFilesList.push(updatedFileName);
        } else {
          updatedFileName = `${newFileName}.${parts[parts.length - 1]}`;
          tempFilesList.push(newFileName);
        }
        fileName = updatedFileName;
      }
  
      Object.defineProperty(file, "name", {
        writable: true,
        value: fileName.replace("#", ""),
      });
  
      tempFiles = [...tempFiles, file];
    }
  
    setFilesList(tempFilesList);

    setAttachmentTypesCount(attachmentTypesCount);

    setSelectedFiles(tempFiles);

    setMiscType("");

    setCustomPhotoType("");
  
    event.target.value = "";

    // Show error message if any corrupted PDFs are found
    if (errorFiles.length > 0) {
      setErrorMessage(`Corrupted PDF Files Found: ${errorFiles.join(", ")}`);
      await delay(5000);
      setErrorMessage(false);
    }
  };

  const handleSnackbarClose = () =>
    setSnackbar((current) => ({
      ...current,
      messsage: "",
      open: false,
    }));

  const onFileCancelHandler = (index, uploaded) => {
    if (!uploaded) {
      const tempFiles = [...selectedFiles];
      tempFiles.splice(index, 1);
      setSelectedFiles(tempFiles);
    } else {
      const tempFiles = [...note.attachments];
      tempFiles[index].isDelete = true;
      setNote((val) => {
        return {
          ...val,
          attachments: tempFiles,
        };
      });
    }
  };
  return (
    <React.Fragment>
      <WholeScreenModal
        show={showEmailModal}
        hide={() => setShowEmailModal(false)}
      >
        <Prompt
          response={(response) => {
            setShowEmailModal(false);
            handleSubmit(response);
            setResonseEmail(response);
          }}
          headingText={Text.emailPromptHeading}
          question={Text.emailPromptBody}
          acceptText={Text.emailPromptAcceptBtn}
          rejectText={Text.emailPromptRejectBtn}

        />
      </WholeScreenModal>
      <Container maxWidth="sm">
        <Grid container spacing={3} style={{ marginTop: "10px" }}>
          <Grid item xs={12}>
            {errorMessage && (
              <Alert
                severity="error"
                className="error_snackbar"
                style={{ minWidth: "100%", fontWeight: 600 }}
              >
                {errorMessage}
              </Alert>
            )}
          </Grid>
        </Grid>
        <form style={{ width: "100%" }} onSubmit={(e) => emailHandler(e)}>
          <Grid container spacing={3} style={{ marginTop: "10px" }}>
            {/* {note.type !== "Custom Note" && ( */}
            <>
              <Grid item xs={12} style={{ textAlign: "left" }}>
                <InputLabel id="services_select_label">Service</InputLabel>
                <CustomSelect
                  options={convertServicesObj(Notes)}
                  onChange={handleNoteChange("serviceId")}
                  value={note.serviceId}
                />
              </Grid>
              {isNoteTimeWorkValidation ? <Grid item xs={5} textAlign={"center"}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  {note?.work_start_time !== null ? (
                    <KeyboardDateTimePicker
                      maxDate={new Date()}
                      value={startTime}
                      onChange={StartDateTime}
                      label={"Work Start Time"}
                      format="MM-DD-YYYY hh:mm a"
                      showTodayButton
                    />
                  ) : (
                    <TextField label="Work Start Time" value="" disabled />
                  )}
                </MuiPickersUtilsProvider>
              </Grid> : null}
              <Grid item xs={isNoteTimeWorkValidation ? 2 : 4}>
                <TextField
                  id="rate"
                  name="rate"
                  label={Text.rateLabel}
                  fullWidth
                  autoComplete="rate"
                  required
                  inputProps={{
                    value: note.rate,
                    min: 0,
                    step: 0.01,
                  }}
                  disabled={note.type === "fixed"}
                  onChange={handleNoteChange("rate")}
                  type={"number"}
                />
              </Grid>
              <Grid item xs={isNoteTimeWorkValidation ? 2 : 4}>
                <TextField
                  id="hour"
                  name="hour"
                  label="Hour"
                  fullWidth
                  autoComplete="hour"
                  required
                  inputProps={{
                    value: note?.hour,
                    min: 0,
                    step: 0.01,
                  }}
                  type={"number"}
                  onChange={handleNoteChange("hour")}
                />
              </Grid>

              <Grid item xs={isNoteTimeWorkValidation ? 3 : 4}>
                <TextField
                  id="amount"
                  name="amount"
                  label={Text.amountLabel}
                  fullWidth
                  autoComplete="amount"
                  required
                  inputProps={{
                    value: note.amount,
                    min: 0,
                    step: 0.01,
                  }}
                  disabled={disabled}
                  onChange={handleNoteChange("amount")}
                  type={"number"}
                />
              </Grid>
            </>
            {/* )} */}

            <Grid item xs={12}>
              <TextField
                id="description"
                name="description"
                label={Text.descriptionLabel}
                fullWidth
                autoComplete="description"
                required
                multiline
                inputProps={{
                  value: note.description,
                }}
                disabled={disabled}
                onChange={handleNoteChange("description")}
              />
            </Grid>

            {selectedService && selectedService.report_number && (
              <Grid item xs={6}>
                <TextField
                  id="reportNo"
                  name="reportNo"
                  label={Text.reportNoLabel}
                  fullWidth
                  autoComplete="reportNo"
                  required
                  inputProps={{
                    value: note.reportNo,
                  }}
                  disabled={disabled}
                  onChange={handleNoteChange("reportNo")}
                />
              </Grid>
            )}

            {selectedService && selectedService.state_incident_no && (
              <Grid item xs={6}>
                <TextField
                  id="state_incident_no"
                  name="state_incident_no"
                  label={Text.stateIncidentNoLabel}
                  fullWidth
                  autoComplete="state_incident_no"
                  required
                  inputProps={{
                    value: note.state_incident_no,
                  }}
                  onChange={handleNoteChange("state_incident_no")}
                />
              </Grid>
            )}

            {selectedService && selectedService.national_incident_no && (
              <Grid item xs={6}>
                <TextField
                  id="incident_no"
                  name="incident_no"
                  label={Text.nationalIncidentNoLabel}
                  fullWidth
                  autoComplete="incident_no"
                  required
                  inputProps={{
                    value: note.incident_no,
                  }}
                  onChange={handleNoteChange("incident_no")}
                />
              </Grid>
            )}

            {selectedService && selectedService.ip_address_identifier && (
              <Grid item xs={6}>
                <TextField
                  id="ipAddressIdentifier"
                  name="ipAddressIdentifier"
                  label={Text.ipAddressLabel}
                  fullWidth
                  autoComplete="ipAddressIdentifier"
                  required
                  inputProps={{
                    value: note.ipAddressIdentifier,
                  }}
                  disabled={disabled}
                  onChange={handleNoteChange("ipAddressIdentifier")}
                />
              </Grid>
            )}

            {selectedService && selectedService.owner && (
              <Grid item xs={6}>
                <TextField
                  id="owner"
                  name="owner"
                  label={Text.ownerLabel}
                  fullWidth
                  autoComplete="owner"
                  required
                  inputProps={{
                    value: note.owner,
                  }}
                  disabled={disabled}
                  onChange={handleNoteChange("owner")}
                />
              </Grid>
            )}

            {selectedService && selectedService.salvage_containers && (
              <Grid item xs={12}>
                <SalvageContainer
                  containers={containers}
                  setContainers={setContainers}
                />
              </Grid>
            )}

            {selectedService && selectedService.samples && (
              <Grid item xs={6}>
                <TextField
                  id="samples"
                  name="samples"
                  label={Text.noSamplesLabel}
                  fullWidth
                  autoComplete="samples"
                  required
                  inputProps={{
                    value: note.samples,
                  }}
                  disabled={disabled}
                  onChange={handleNoteChange("samples")}
                />
              </Grid>
            )}

            {selectedService &&
              selectedService.cost &&
              (selectedService?.name === "Schedule Excavation" ? (
                <Grid item xs={6}>
                  <TextField
                    id="cost"
                    name="cost"
                    label={Text.projectCostLabel}
                    fullWidth
                    autoComplete="cost"
                    required
                    inputProps={{
                      value: note.cost,
                    }}
                    onChange={handleNoteChange("cost")}
                  />
                </Grid>
              ) : (
                <Grid item xs={6}>
                  <TextField
                    id="cost"
                    name="cost"
                    label={Text.estimatedCostLabel}
                    fullWidth
                    autoComplete="cost"
                    required
                    inputProps={{
                      value: note.cost,
                    }}
                    onChange={handleNoteChange("cost")}
                  />
                </Grid>
              ))}

            {selectedService && selectedService.establish_lane_closure && (
              <Grid item xs={6} style={{ textAlign: "left" }}>
                <InputLabel id="establishLaneClosure_select_label">
                  {Text.establishLaneClosureLabel}
                </InputLabel>
                <Select
                  labelId="establishLaneClosure_select_label"
                  id="establishLaneClosure_select"
                  name="establishLaneClosure"
                  style={{ width: "100%" }}
                  inputProps={{
                    value: note.establishLaneClosure,
                  }}
                  disabled={disabled}
                  onChange={handleNoteChange("establishLaneClosure")}
                >
                  <MenuItem value={true}>{Text.establishLaneOption1}</MenuItem>
                  <MenuItem value={false}>{Text.establishLaneOption2}</MenuItem>
                </Select>
              </Grid>
            )}
            {selectedService?.name ===
              Constants.EXCAVATION_COMPLETED_SERVICE_NAME && (
                <Grid item xs={6} style={{ textAlign: "left" }}>
                  <TextBox text={note.excavation_time} heading="Total Time" />
                </Grid>
              )}

            {selectedService && selectedService.excavation_begun && (
              <Grid item xs={6} style={{ textAlign: "left" }}>
                <InputLabel id="excavationBegun_select_label">
                  {Text.excavationBegunLabel}
                </InputLabel>
                <Select
                  labelId="excavationBegun_select_label"
                  id="excavationBegun_select"
                  name="excavationBegun"
                  style={{ width: "100%" }}
                  inputProps={{
                    value: note.excavationBegun,
                  }}
                  disabled={disabled}
                  onChange={handleNoteChange("excavationBegun")}
                >
                  <MenuItem value={true}>
                    {Text.excavationBegunOption1}
                  </MenuItem>
                  <MenuItem value={false}>
                    {Text.excavationBegunOption2}
                  </MenuItem>
                </Select>
              </Grid>
            )}

            {selectedService && selectedService.excavation_completed && (
              <Grid item xs={6} style={{ textAlign: "left" }}>
                <InputLabel id="excavationCompleted_select_label">
                  {Text.excavationCompleted}
                </InputLabel>
                <Select
                  labelId="excavationCompleted_select_label"
                  id="excavationCompleted_select"
                  name="excavationCompleted"
                  style={{ width: "100%" }}
                  inputProps={{
                    value: note.excavationCompleted,
                  }}
                  disabled={disabled}
                  onChange={handleNoteChange("excavationCompleted")}
                >
                  <MenuItem value={true}>
                    {Text.excavationCompletedOption1}
                  </MenuItem>
                  <MenuItem value={false}>
                    {Text.excavationCompletedOption2}
                  </MenuItem>
                </Select>
              </Grid>
            )}

            {selectedService && selectedService.task_associated_relevance && (
              <Grid item xs={12}>
                <TextField
                  id="taskAssociatedRelevance"
                  name="taskAssociatedRelevance"
                  label={Text.taskAssociatedRelevanceLabel}
                  fullWidth
                  autoComplete="taskAssociatedRelevance"
                  required
                  inputProps={{
                    value: note.taskAssociatedRelevance,
                  }}
                  disabled={disabled}
                  onChange={handleNoteChange("taskAssociatedRelevance")}
                />
              </Grid>
            )}

            {selectedService && selectedService.time && (
              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardTimePicker
                    variant="inline"
                    margin="normal"
                    id="time_picker_inline"
                    label={Text.timeLabel}
                    inputProps={{
                      value: note.time,
                    }}
                    disabled={disabled}
                    onChange={(time) =>
                      setNote((val) => ({
                        ...val,
                        ["time"]: time?.format("hh:mmA"),
                      }))
                    }
                    KeyboardButtonProps={{
                      aria_label: "change time",
                    }}
                  />
                  <Button
                    style={{ textAlign: "center" }}
                    variant="contained"
                    color="primary"
                    onClick={() => setNote((val) => ({ ...val, ["time"]: "" }))}
                  >
                    {Text.clearBtnLabel}
                  </Button>
                </MuiPickersUtilsProvider>
              </Grid>
            )}

            {selectedService && selectedService.date && (
              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM-DD-YYYY"
                    margin="normal"
                    id="date_picker_inline"
                    label={selectedService?.name === "Schedule Excavation" ? Text.scheduleDateLabel : Text.dateLabel}
                    value={note?.date || null}
                    className={classes.topMargin}
                    onChange={(date) => {
                      if (date?._i?.length > 1) {
                        setNote((val) => ({ ...val, date: date }));
                      }
                    }}
                    KeyboardButtonProps={{
                      aria_label: "change date",
                    }}
                  />
                  <Button
                    style={{ textAlign: "center" }}
                    variant="contained"
                    color="primary"
                    disabled={disabled}
                    onClick={() => setNote((val) => ({ ...val, ["date"]: "" }))}
                  >
                    {Text.clearBtnLabel}
                  </Button>
                </MuiPickersUtilsProvider>
              </Grid>
            )}

            {selectedService?.no_release == false ? (
              <>
                <Grid
                  item
                  xs={6}
                  className={classes.alignLeft}
                  style={{ alignSelf: "end" }}
                >
                  <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    className={classes.button}
                    onClick={handleAreaClickOpen}
                  >
                    Area Covered By Release
                  </Button>
                </Grid>{" "}
                {releaseCoverages?.length ? (
                  <TableContainer
                    className={classes.bottomSpacing}
                    component={Paper}
                    style={{ margin: "30px 0" }}
                  >
                    <Table>
                      <TableHead className={classes.tableHead}>
                        <TableRow>
                          <TableCell className={classes.tableHeadText}>
                            Type
                          </TableCell>
                          <TableCell className={classes.tableHeadText}>
                            Surface
                          </TableCell>

                          <TableCell className={classes.tableHeadText}>
                            Length
                          </TableCell>
                          <TableCell className={classes.tableHeadText}>
                            Width
                          </TableCell>
                          <TableCell className={classes.tableHeadText}>
                            Diameter
                          </TableCell>
                          <TableCell className={classes.tableHeadText}>
                            Result
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {releaseCoverages.map((coverage, index) => (
                          <TableRow key={index}>
                            <TableCell className="table-td-head">
                              {coverage.footprint_type}
                            </TableCell>
                            <TableCell className="table-td-head">
                              {coverage.surface}
                            </TableCell>
                            <TableCell>
                              {coverage.height || "-"}
                            </TableCell>
                            <TableCell>{coverage.width || "-"}</TableCell>
                            <TableCell>{coverage.diameter || "-"}</TableCell>
                            <TableCell>
                              {coverage.covered_area || "-"}
                            </TableCell>
                            <TableCell>
                              <CloseIcon
                                onClick={() => handleRemoveLocationData(index, coverage.id)}
                                width="10px"
                                style={{ cursor: "pointer" }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : null}
              </>
            ) : null}

            {((selectedService && selectedService.upload_attachment) ||
              currentUser.data?.role.role === ROLES.CORPORATE_LOGGER) && (
                <Grid container>
                  <Grid item xs={12}>
                    <NoteAttachmentTable
                      files={note.attachments}
                      preview={selectedFiles}
                      handleCancel={onFileCancelHandler}
                      canDelete={isPESUser(currentUser.data?.role.role)}
                    ></NoteAttachmentTable>
                  </Grid>
                  <Grid item xs={12} className={classes.alignRight}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="form-control-file-type">
                        {Text.attachmentTypeLabel}
                      </InputLabel>
                      <Select
                        labelId="select-file-type-label"
                        id="select-file-type"
                        value={fileType}
                        onChange={(event) => {
                          setFileType(event.target.value);
                          setPhotoType("");
                        }}
                      >
                        <MenuItem value="">
                          <em>Select Attachment Type</em>
                        </MenuItem>
                        {orderAlphabatically(
                          getAttachmentTypes(selectedService?.name)
                        ).map((val, index) => (
                          <MenuItem key={index} value={val}>
                            {val}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {fileType === "Misc" ? (
                      <FormControl className={classes.formControl}>
                        <TextField
                          id="miscType"
                          name="miscType"
                          label="Misc Type"
                          autoComplete="miscType"
                          onChange={(event) =>
                            setMiscType(`${event.target.value}`)
                          }
                        />
                      </FormControl>
                    ) : null}

                    {fileType === "Photo" ? (
                      <FormControl className={classes.formControl}>
                        <InputLabel id="form-control-file-type">
                          {Text.photoTypeLabel}
                        </InputLabel>
                        <Select
                          labelId="select-file-type-label"
                          id="select-photo-type"
                          value={photoType}
                          disabled={fileType !== "Photo"}
                          onChange={(event) => setPhotoType(event.target.value)}
                        >
                          <MenuItem value="">
                            <em>Select Photo Type</em>
                          </MenuItem>
                          {orderAlphabatically(photoTypes).map((val, index) => (
                            <MenuItem key={index} value={val}>
                              {val}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : null}

                    {photoType === "Custom" ? (
                      <FormControl className={classes.formControl}>
                        <TextField
                          id="customPhotoType"
                          name="customPhotoType"
                          label="Custom Photo Type"
                          autoComplete="customPhotoType"
                          onChange={(event) =>
                            setCustomPhotoType(`${event.target.value}`)
                          }
                        />
                      </FormControl>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} className={classes.alignRight}>
                    <div>
                      <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        className={classes.button}
                        disabled={
                          !fileType || (fileType === "Photo" && !photoType)
                        }
                      >
                        {Text.selectFilesBtn}
                        <input
                          type="file"
                          accept=".jpg, .png, .jpeg, .TIF, .pdf, .doc, .docx, .xls, .xlsx"
                          onChange={onFileChangeHandler}
                          hidden
                          multiple
                        />
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              )}

            {selectedService &&
              (selectedService.projected_eta || selectedService.actual_eta) && (
                <Grid item xs={12} textAlign={"center"}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardTimePicker
                      ampm={false}
                      keyboardIcon={null}
                      disableIgnoringDatePartForTimeValidation
                      disableOpenPicker
                      disablePast={
                        selectedService?.projected_eta ? true : false
                      }
                      error={false}
                      helperText={null}
                      variant="inline"
                      placeholder="HH:MM"
                      label={
                        selectedService?.projected_eta
                          ? "Projected ETA"
                          : "Actual ETA"
                      }
                      mask="__:__"
                      views={["hours", "minutes"]}
                      format="HH:mm"
                      inputValue={
                        selectedService?.projected_eta
                          ? note?.projected_eta
                            ? note?.projected_eta
                            : "00:00"
                          : note?.actual_eta
                            ? note?.actual_eta
                            : "00:00"
                      }
                      onChange={(time) =>
                        setNote((val) => ({
                          ...val,
                          [selectedService?.projected_eta
                            ? "projected_eta"
                            : "actual_eta"]: time && Object.entries(time)[1][1],
                        }))
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              )}

            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                disabled={isButtonDisable}
                type="button"
                onClick={() => emailHandler()}>
                {Text.updateBtn}
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={() => {
                  closeNoteFrom(true);
                  setErrorMessage(false);
                  noteWorkTimeValidationReset();
                }}
              >
                {Text.cancelBtn}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
      <CustomSnackBar
        severity={snackbar.severity}
        open={snackbar.open}
        onClose={handleSnackbarClose}
        snackBarMessage={snackbar.message}
      />

      <Dialog
        open={openAreaCoverModal}
        onClose={handleAreaClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="area-covered-modal "
      >
        <div className="area-covered-modal-header">
          <div>
            <DialogTitle id="alert-dialog-title">
              Basic Footprint Type
            </DialogTitle>
          </div>
          <div onClick={handleAreaClose} className="close-icon">
            <CloseIcon></CloseIcon>
          </div>
        </div>
        <DialogContent className="CustomScrollbar">
          <AreaCoveredModal
            SetReleaseCoverages={SetReleaseCoverages}
            releaseCoverages={releaseCoverages}
            handleClose={handleAreaClose}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};


const mapStateToProps = ({ spill: { currentSpill, noteWorkStartTimeValidation }, user, }) => {
  return {
    currentSpill,
    disabled: !user.currentUser?.data?.role?.permission?.edit_complete_note,
    currentUser: user.currentUser,
    noteWorkStartTimeValidation,
  };
};

const mapDispatchToProps = (dispatch) => ({
  editNotes: bindActionCreators(spillActions.editNotes, dispatch),
  noteWorkTimeValidation: bindActionCreators(spillActions.noteWorkTimeValidation, dispatch),
  noteWorkTimeValidationReset: bindActionCreators(spillActions.noteWorkTimeValidationReset, dispatch),

});

EditNote.proptype = {
  currentUser: PropTypes.object.isRequired,
  closeNoteFrom: PropTypes.func.isRequired,
  editNotes: PropTypes.func.isRequired,
  noteWorkTimeValidationReset: PropTypes?.func?.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditNote)
);

