import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import moment from 'moment'
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import {
  Grid,
  Radio,
  Button,
  Select,
  Snackbar,
  Checkbox,
  MenuItem,
  FormGroup,
  Container,
  TextField,
  Typography,
  InputLabel,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import * as spillActions from '../../../../../actionCreators/Spill';

import { forceToCST, isPESUser } from '../../../../../utils';
import { CustomProgressLoader } from '../../../../../Components';
import { USER_TYPE } from '../../../../../utils/keyMappers';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    '&$checked': {
      color: '#2F7D32',
    },
  },
  checked: {},
  avatar: {
    backgroundColor: green[500],
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  hide: {
    visibility: 'none',
  },
  clearSearchButton: {
    textAlign: 'right',
  },
  alignLeft: {
    textAlign: 'left',
  },
  checkboxColor: {
    color: '#2F7D32',
  },
  radioGroupDisplay: {
    display: 'inline',
  },
  leftAlign: {
    textAlign: 'left',
  },
}));

const RevenueAccrualReport = ({
  history,
  loading,
  success,
  statuses,
  goToSpills,
  currentUser,
  getStatuses,
  searchSpills,
  reportsAllow,
  spillsLoading,
  batchUpdateAllow,
  spillFiltersState,
  revenueAccrualAllow,
  switchReportsHandler,
  setSpillDataForFilter,
  setFinalSpillsSearchData,
}) => {
  const classes = useStyles();
  const location = history.location;
  const { handleSubmit } = useForm();

  document.title = 'Search';

  const [status, setStatus] = useState({
    open: { value: false, label: 'Open: Open', name: 'open' },
    work_in_progress: {
      value: false,
      label: 'Open: Work in Progress',
      name: 'work_in_progress',
    },
    site_work_complete: {
      value: false,
      label: 'Open: Site Work Complete',
      name: 'site_work_complete',
    },
    extended_remediation: {
      value: false,
      label: 'Open: Extended Remediation',
      name: 'extended_remediation',
    },
    pending_excavation: {
      value: false,
      label: 'Open: Pending Excavation',
      name: 'pending_excavation',
    },
    documentation_in_review: {
      value: false,
      label: 'Open: Documentation In Review',
      name: 'documentation_in_review',
    },
    pending_disposal: {
      value: false,
      label: 'Open: Pending Disposal',
      name: 'pending_disposal',
    },
    ready_to_invoice: {
      value: false,
      label: 'Open: Ready to Invoice',
      name: 'ready_to_invoice',
    },
    final_review: {
      value: false,
      label: 'Open: Final Review',
      name: 'final_review',
    },
    ready_to_close: {
      value: false,
      label: 'Open: Ready to Close',
      name: 'ready_to_close',
    },
    documentation_sent_back_to_contractor_for_revision: {
      value: false,
      label: 'Open: Documentation Sent Back to Contractor for Revision',
      name: 'documentation_sent_back_to_contractor_for_revision',
    },
  });

  const [spillSelectData, setSpillSelectData] = useState({
    date_from: null,
    date_to: null,
  });

  const [snackBarSeverity, setSnackBarSeverity] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  useEffect(() => {
    handleClearSearch();
  }, [location.key]);

  // Selecting all status by default
  useEffect(() => {
    spillStatusFilteringOpen({ target: { checked: true } });
  }, []);

  const handleSearchDateChange = (key) => (date) => {
    setSpillSelectData((val) => ({
      ...val,
      [key]: date,
    }));
  };

  // Validation function for date format
  const isValidDate = (value) => {
    // Skip validation if the value is empty
    if (!value) {
      return true;
    }
    return moment(value, 'MM-DD-YYYY hh:mm a', true).isValid();
  };

  const onFinish = async (searchData) => {
    // getStatuses();
    let statusData = [];
    const { date_from, date_to } = spillSelectData || {};
    const isBothDatesPresent = date_from && date_to;

    if (isBothDatesPresent && moment(date_from)?.isAfter(date_to)) {
      setSnackBarSeverity("error");
      setSnackBarMessage(
        "The 'date to' should be greater than 'date from'. Please select valid date range."
      );
      setSnackBarOpen(true);
      return;
    }

    const isDateInvalid = [date_from, date_to]?.some(date => !isValidDate(date));

    if (isDateInvalid) {
      setSnackBarSeverity("error");
      setSnackBarMessage("Please enter valid date");
      setSnackBarOpen(true);
      return;
    }

    for (let data in status) {
      if (status[data].value !== false) {
        statusData?.push(status[data].label);
      }
    }

    searchData = {
      ...searchData,
      opened_on: forceToCST(spillSelectData.date_from),
      opened_to: forceToCST(spillSelectData.date_to),
      statusData,
      id: currentUser?.data?.id,
      role: currentUser?.data?.role?.role,
      permission: currentUser?.data?.role?.permission?.view_related_spills,
      page: 0,
      isRevenueAccrualReport: true,
    };

    const dataForFilter = [
      spillSelectData.date_from
      ? `Date From: ${spillSelectData.date_from}, `
      : null,
      spillSelectData.date_to ? `Date To: ${spillSelectData.date_to}, ` : null,
      statusData?.length > 0 ? `Status: [${statusData}], ` : null,
    ];

    setSpillDataForFilter(dataForFilter);

    setFinalSpillsSearchData(searchData);

    searchSpills({
      ...searchData,
      userType: currentUser?.data?.test_user
        ? USER_TYPE.TEST
        : USER_TYPE.GENERAL,
    });

    history.push(
      `/dashboard/search-results?token=${batchUpdateAllow}&report=${reportsAllow}&revenue-accrual=true`
    );
  };

  const handleClearSearch = () => {
    setSpillSelectData({
      date_to: null,
      date_from: null,
    });

    let statusData = {};

    if (status) {
      for (const [key, value] of Object.entries(status)) {
        statusData = {
          ...statusData,
          [key]: { ...value, value: false },
        };
      }
    }

    setStatus({ ...statusData });
  };

  const handleSnackBarClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  };

  const spillStatusFilteringOpen = (event) => {
    let statusData = {};

    for (const [key, value] of Object?.entries(status)) {
      if (value?.label?.search('Open: ') > -1) {
        statusData = {
          ...statusData,
          [key]: { ...value, value: event.target.checked },
        };
      } else {
        statusData = {
          ...statusData,
          [key]: { ...value },
        };
      }
    }
    setStatus({ ...statusData });
  };

  const containsOnly = (checkingArray, dataArray) => {
    return dataArray?.every((elem) => checkingArray?.includes(elem));
  };

  const allCheck = (tempStatuses, type) => {
    let checkingOpen = false;
    let allOpenCheckValues = [];
    let statusData = tempStatuses;

    for (const [key, value] of Object?.entries(tempStatuses)) {
      if (value?.label?.search('Open: ') > -1) {
        if (value?.name !== 'open') {
          allOpenCheckValues?.push(value?.value);
        }
      }
    }

    if (containsOnly([true], allOpenCheckValues)) {
      checkingOpen = allOpenCheckValues?.every((val, i, arr) => val === arr[0]);
    }

    if (checkingOpen === true) {
      statusData = {
        ...tempStatuses,
        ['open']: { value: true, label: 'Open: Open', name: 'open' },
      };
    }

    setStatus({ ...statusData });
  };

  const handleChangeCheckbox = (event) => {
    let statusType = 'open';

    let tempStatus = {
      ...status,
      [event.target.name]: {
        value: event.target.checked,
        label: status[event.target.name].label,
        name: status[event.target.name].name,
      },
    };

    setStatus(tempStatus);
    allCheck(tempStatus, statusType);
  };

  const {
    open = null,
    work_in_progress = null,
    site_work_complete = null,
    extended_remediation = null,
    pending_excavation = null,
    documentation_in_review = null,
    pending_disposal = null,
    ready_to_invoice = null,
    final_review = null,
    ready_to_close = null,
    documentation_sent_back_to_contractor_for_revision = null,
  } = status || {};

  return (
    <React.Fragment>
       <Snackbar
        open={snackBarOpen}
        autoHideDuration={5000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleSnackBarClose} severity={snackBarSeverity}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <div style={{ paddingTop: 10 }} />

      <Container maxWidth='lg'>
        <form
          onSubmit={handleSubmit((data) => onFinish(data))}
          style={{ width: '100%' }}
        >
          <Grid container spacing={3}>
            <Grid item xs={8} style={{ textAlign: 'left' }}>
              <Typography variant='h5' gutterBottom>
                Revenue Accrual Report
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              <Button
                variant='contained'
                color='primary'
                type='reset'
                onClick={() => handleClearSearch()}
              >
                Clear filters
              </Button>
            </Grid>
          </Grid>
          {loading ? (
            <CustomProgressLoader show={true} />
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12} className={classes.leftAlign}>
                <InputLabel
                  id='location_type_label'
                  className={classes.locationTypeLabel}
                >
                  By giving the "start date/time" and "end date/time", you can filter the
                  spills whose status is "Open: ***".
                  (Spill status may be changed now).
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='subtitle2'>
                  Open (MM-DD-YYYY hh:mm am/pm)
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDateTimePicker
                    value={spillSelectData?.date_from}
                    onChange={handleSearchDateChange('date_from')}
                    label={'Start Date/Time'}
                    showTodayButton
                    ampmInClock={true}
                    clearable={true}
                    format='MM-DD-YYYY hh:mm a'
                    initialFocusedDate={moment(new Date()).startOf('day').format('MM-DD-YYYY hh:mm a')}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDateTimePicker
                    value={spillSelectData?.date_to}
                    onChange={handleSearchDateChange('date_to')}
                    label={'End Date/Time'}
                    showTodayButton
                    ampmInClock={true}
                    clearable={true}
                    format='MM-DD-YYYY hh:mm a'
                    maxDate={new Date()}
                    initialFocusedDate={moment(new Date()).startOf('day').format('MM-DD-YYYY hh:mm a')}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={2}>
                <Typography variant='subtitle1'>Open Spill Status</Typography>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={open?.value}
                        onChange={spillStatusFilteringOpen}
                        name='open'
                        classes={{
                          root: classes.root,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label='Open (any)'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={work_in_progress?.value}
                        onChange={handleChangeCheckbox}
                        name='work_in_progress'
                        classes={{
                          root: classes.root,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label='Work In Progress'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={site_work_complete?.value}
                        onChange={handleChangeCheckbox}
                        name='site_work_complete'
                        classes={{
                          root: classes.root,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label='Site Work Complete'
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={extended_remediation?.value}
                        onChange={handleChangeCheckbox}
                        name='extended_remediation'
                        classes={{
                          root: classes.root,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label='Extended Remediation'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={pending_excavation?.value}
                        onChange={handleChangeCheckbox}
                        name='pending_excavation'
                        classes={{
                          root: classes.root,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label='Pending Excavation'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={documentation_in_review?.value}
                        onChange={handleChangeCheckbox}
                        name='documentation_in_review'
                        classes={{
                          root: classes.root,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label='Documentation In Review'
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={pending_disposal?.value}
                        onChange={handleChangeCheckbox}
                        name='pending_disposal'
                        classes={{
                          root: classes.root,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label='Pending Disposal'
                  />
                  {isPESUser(currentUser?.data?.role?.role) && (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={ready_to_invoice?.value}
                            onChange={handleChangeCheckbox}
                            name='ready_to_invoice'
                            classes={{
                              root: classes.root,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label='Ready to Invoice'
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={final_review?.value}
                            onChange={handleChangeCheckbox}
                            name='final_review'
                            classes={{
                              root: classes.root,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label='Final Review'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={ready_to_close?.value}
                            onChange={handleChangeCheckbox}
                            name='ready_to_close'
                            classes={{
                              root: classes.root,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label='Ready to Close'
                      />
                    </>
                  )}
                  <FormControlLabel
                    control={
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                        }}
                      >
                        <Checkbox
                          checked={
                            documentation_sent_back_to_contractor_for_revision?.value
                          }
                          onChange={handleChangeCheckbox}
                          name='documentation_sent_back_to_contractor_for_revision'
                          classes={{
                            root: classes.root,
                            checked: classes.checked,
                          }}
                        />
                        <Typography
                          style={{ paddingTop: '8px', textAlign: 'left' }}
                        >
                          Documentation Sent Back to Contractor for Revision
                        </Typography>
                      </div>
                    }
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12} style={{ textAlign: 'right' }}>
                <Button
                  className={classes.button}
                  variant='contained'
                  color='primary'
                  type='submit'
                >
                  Search
                </Button>
                <Button
                  className={classes.button}
                  variant='contained'
                  color='primary'
                  onClick={() => switchReportsHandler && switchReportsHandler(null) || goToSpills()}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          )}
        </form>
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = ({
  user,
  spill: {
    status: { statuses },
    success,
    spillFiltersState,
    loading: spillsLoading,
  },
}) => ({
  currentUser: user.currentUser,
  loading: user.loading || spillsLoading,
  statuses: statuses,
  success: success,
  spillFiltersState,
  spillsLoading,
});

const mapDispatchToProps = (dispatch) => ({
  searchSpills: bindActionCreators(spillActions.searchSpills, dispatch),
  getStatuses: bindActionCreators(spillActions.getStatuses, dispatch),
  setSpillFilters: bindActionCreators(spillActions.setSpillFilters, dispatch),
  setSpillDataForFilter: bindActionCreators(spillActions.setSpillDataForFilter, dispatch),
  setFinalSpillsSearchData: bindActionCreators(spillActions.setFinalSpillsSearchData, dispatch),
});

RevenueAccrualReport.propTypes = {
  user: PropTypes.object.isRequired,
  spills: PropTypes.object.isRequired,
  searchSpills: PropTypes.func.isRequired,
  success: PropTypes.bool.isRequired,
  invoiceSubmittedToClientReportDownload: PropTypes.object.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RevenueAccrualReport)
);
