import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import moment from 'moment'
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import {
  Grid,
  Button,
  Container,
  Typography,
  InputLabel,
  Snackbar,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import * as spillActions from '../../../../../actionCreators/Spill';

import { forceToCST } from '../../../../../utils';
import { CustomProgressLoader } from '../../../../../Components';
import { USER_TYPE } from '../../../../../utils/keyMappers';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    '&$checked': {
      color: '#2F7D32',
    },
  },
  checked: {},
  avatar: {
    backgroundColor: green[500],
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  hide: {
    visibility: 'none',
  },
  clearSearchButton: {
    textAlign: 'right',
  },
  alignLeft: {
    textAlign: 'left',
  },
  checkboxColor: {
    color: '#2F7D32',
  },
  radioGroupDisplay: {
    display: 'inline',
  },
  leftAlign: {
    textAlign: 'left',
  },
}));

const InvoiceSubmittedReport = ({
  history,
  loading,
  currentUser,
  goToSpills,
  searchSpills,
  reportsAllow,
  switchReportsHandler,
  batchUpdateAllow,
  setSpillDataForFilter,
  setFinalSpillsSearchData,
  invoiceSubmittedToClientReportDownload,
  updateInvoiceSubmittedToClientReportDownloadState
}) => {
  const classes = useStyles();

  const location = history.location;

  const { handleSubmit } = useForm();

  document.title = 'Search';

  const [spillSelectData, setSpillSelectData] = useState(
    invoiceSubmittedToClientReportDownload?.data || {
      date_to: null,
      date_from: null,
    }
  );

  const [snackBarSeverity, setSnackBarSeverity] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  useEffect(() => {
    handleClearSearch();
  }, [location.key]);

  // prepopulate with the data from redux
  useEffect(() => {
    if(Object.entries(invoiceSubmittedToClientReportDownload?.data || {})?.length) {
      setSpillSelectData(invoiceSubmittedToClientReportDownload?.data || {
        date_to: null,
        date_from: null,
      });
    }
  }, [])


  // update the redux state
  useEffect(() => {
    if(spillSelectData?.date_to || spillSelectData?.date_from) {
      updateInvoiceSubmittedToClientReportDownloadState(spillSelectData)
    }
  }, [spillSelectData?.date_to, spillSelectData?.date_from])

  const handleSearchDateChange = (key) => (date) => {
    setSpillSelectData((val) => ({
      ...val,
      [key]: date,
    }));
  };

  // Validation function for date format
  const isValidDate = (value) => {
    // Skip validation if the value is empty
    if (!value) {
      return true;
    }
    return moment(value, 'MM-DD-YYYY hh:mm a', true).isValid();
  };

  const onFinish = async (searchData) => {

    const { date_from, date_to } = spillSelectData || {};
    const isBothDatesPresent = date_from && date_to;

    if (isBothDatesPresent && moment(date_from)?.isAfter(date_to)) {
      setSnackBarSeverity("error");
      setSnackBarMessage(
        "The 'date to' should be greater than 'date from'. Please select valid date range."
      );
      setSnackBarOpen(true);
      return;
    }

    const isDateInvalid = [date_from, date_to]?.some(date => !isValidDate(date));

    if (isDateInvalid) {
      setSnackBarSeverity("error");
      setSnackBarMessage("Please enter valid date");
      setSnackBarOpen(true);
      return;
    }

    searchData = {
      ...searchData,
      ...spillSelectData,
      statusData: [],
      id: currentUser?.data?.id,
      role: currentUser?.data?.role?.role,
      permission: currentUser?.data?.role?.permission?.view_related_spills,
      page: 0,
      isReport: true,
    };

    const dataForFilter = [
      spillSelectData.date_from
      ? `Opened From: ${spillSelectData.date_from}, `
      : null,
      spillSelectData.date_to ? `Date To: ${spillSelectData.date_to}, ` : null,
    ];

    setSpillDataForFilter(dataForFilter);

    setFinalSpillsSearchData({
      ...searchData,
      date_to: forceToCST(searchData.date_to),
      date_from: forceToCST(searchData.date_from),
    });
    searchSpills({
      ...searchData,
      date_to: forceToCST(searchData.date_to),
      date_from: forceToCST(searchData.date_from),
      userType: currentUser?.data?.test_user
        ? USER_TYPE.TEST
        : USER_TYPE.GENERAL,
    });

    history.push(
      `/dashboard/search-results?token=${batchUpdateAllow}&report=${reportsAllow}`
    );
  };

  const handleClearSearch = () => {
    setSpillSelectData({
      date_to: null,
      date_from: null,
    });
  };

  const handleSnackBarClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  };

  return (
    <React.Fragment>
       <Snackbar
        open={snackBarOpen}
        autoHideDuration={5000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleSnackBarClose} severity={snackBarSeverity}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <div style={{ paddingTop: 10 }} />

      <Container maxWidth='lg'>
        <form
          onSubmit={handleSubmit((data) => onFinish(data))}
          style={{ width: '100%' }}
        >
          <Grid container spacing={3}>
            <Grid item xs={8} style={{ textAlign: 'left' }}>
              <Typography variant='h5' gutterBottom>
                Report Invoice Submitted to Client
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              <Button
                variant='contained'
                color='primary'
                type='reset'
                onClick={() => handleClearSearch()}
              >
                Clear filters
              </Button>
            </Grid>
          </Grid>
          {loading ? (
            <CustomProgressLoader show={true} />
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12} className={classes.leftAlign}>
                <InputLabel
                  id='location_type_label'
                  className={classes.locationTypeLabel}
                >
                  By giving the "date from" and "date to" you can filter the
                  spills whose status is in "Invoice Submitted to Client".
                  (Spill status may be changed now).
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='subtitle2'>
                  Date Range (and format) (MM-DD-YYYY hh:mm am/pm)
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDateTimePicker
                    value={spillSelectData?.date_from}
                    onChange={handleSearchDateChange('date_from')}
                    label={'Date From'}
                    showTodayButton
                    ampmInClock={true}
                    clearable={true}
                    format='MM-DD-YYYY hh:mm a'
                    initialFocusedDate={moment(new Date()).startOf('day').format('MM-DD-YYYY hh:mm a')}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDateTimePicker
                    value={spillSelectData?.date_to}
                    onChange={handleSearchDateChange('date_to')}
                    label={'Date To'}
                    showTodayButton
                    ampmInClock={true}
                    clearable={true}
                    format='MM-DD-YYYY hh:mm a'
                    initialFocusedDate={moment(new Date()).startOf('day').format('MM-DD-YYYY hh:mm a')}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={12} style={{ textAlign: 'right' }}>
                <Button
                  className={classes.button}
                  variant='contained'
                  color='primary'
                  type='submit'
                >
                  Search
                </Button>
                <Button
                  className={classes.button}
                  variant='contained'
                  color='primary'
                  onClick={() => switchReportsHandler && switchReportsHandler(null) || goToSpills()}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          )}
        </form>
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = ({
  user,
  spill: {
    loading,
    invoiceSubmittedToClientReportDownload
  },
  client,
  agency,
  contractor,
}) => ({
  currentUser: user.currentUser,
  loading:
    user.loading ||
    loading ||
    client.loading ||
    agency.loading ||
    contractor.loading,
    invoiceSubmittedToClientReportDownload
});

const mapDispatchToProps = (dispatch) => ({
  searchSpills: bindActionCreators(spillActions.searchSpills, dispatch),
  setSpillDataForFilter: bindActionCreators(
    spillActions.setSpillDataForFilter,
    dispatch
  ),
  setFinalSpillsSearchData: bindActionCreators(
    spillActions.setFinalSpillsSearchData,
    dispatch
  ),
  updateInvoiceSubmittedToClientReportDownloadState: bindActionCreators(spillActions.updateInvoiceSubmittedToClientReportDownloadState, dispatch)
});

InvoiceSubmittedReport.propTypes = {
  user: PropTypes.object.isRequired,
  spills: PropTypes.object.isRequired,
  searchSpills: PropTypes.func.isRequired,
  getOrganizationNames: PropTypes.func.isRequired,
  getAgencies: PropTypes.func.isRequired,
  getContractorsWithAddress: PropTypes.func.isRequired,
  contractors: PropTypes.func.isRequired,
  getUsersForEmail: PropTypes.func.isRequired,
  invoiceSubmittedToClientReportDownload: PropTypes.object.isRequired,
  updateInvoiceSubmittedToClientReportDownloadState: PropTypes.func.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InvoiceSubmittedReport)
);
