import React from 'react';
import Moment from 'moment';

import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { getFormattedTime, canSeeStampsOrAmounts } from '../../../../../utils';

const styles = StyleSheet.create({
  textGreen: {
    color: 'green',
  },
  row: {
    content: '',
    display: 'flex',
    flexDirection: 'row',
  },
  col: {
    float: 'left',
    width: '100%',
    padding: '0 5px ',
    height: 'auto',
  },
  colMd: {
    width: '70%',
    float: 'left',
    height: 'auto',
    display: 'flex',
  },
  colSm: {
    width: '30%',
    float: 'right',
    height: 'auto',
  },
  page: {
    backgroundColor: '#ffffff',
    padding: '10px 0px',
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  sectionTitle: {
    // fontFamily: "Roboto-Bold",
    fontWeight: 'bold',
    marginLeft: '10px',
    marginTop: '10px',
  },
  mainHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'flex-end',
  },
  mainHeaderHeading: {
    fontSize: '24px',
    color: 'black',
    marginTop: '15px',
    marginRight: '15px',
  },
  mainHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 9,
    alignContent: 'flex-end',
    marginLeft: '10px',
  },
  logo: {
    color: 'green',
    fontSize: '48px',
    fontWeight: 900,
    marginBottom: '10px',
  },
  mainHeaderSubContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    marginTop: 12,
  },
  subHeading: {
    fontSize: '19px',
    color: 'black',
  },
  subHeadingDesc: {
    fontSize: '12px',
    fontWeight: 300,
  },
  title: {
    backgroundColor: '#f6f6f5',
  },
  noteContainer: {
    borderTop: '1px solid grey',
    display: 'grid',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  subNoteContainer: {
    padding: '10px 0px',
  },
  headerHeading: {
    color: 'green',
    marginRight: '10px',
    fontSize: 10,
    fontWeight: 800,
    marginLeft: '10px',
    marginTop: '5px',
    // fontFamily: "Roboto-Bold",
  },
  headerValue: {
    color: 'black',
    fontWeight: '600px',
    fontSize: 10,
    maxWidth: '50%',
    marginTop: '5px',
    marginRight: '20px',
    marginLeft: '10px',
  },
  headerValueCustom: {
    color: 'black',
    fontWeight: '600px',
    fontSize: 10,
    marginTop: '5px',
    marginRight: '20px',
    marginLeft: '10px',
  },
  headerValueKeyBold: {
    color: '#6B6B6B',
    fontWeight: '600px',
    fontSize: 10,
    // fontFamily: "Roboto-Bold",
  },
  headerValueKeyRegular: {
    color: '#6B6B6B',
    fontWeight: '600px',
    fontSize: 10,
    // fontFamily: "Roboto-Regular",
  },
  spaceBottomSmall: {
    marginBottom: '10px',
  },
  spaceBottomMedium: {
    marginBottom: '25px',
  },
  spaceBottomLarge: {
    marginBottom: '50px',
  },
  spaceBottomExtraLarge: {
    marginBottom: '65px',
  },
  spaceTopSmall: {
    marginBottom: '10px',
  },
  spaceTopMedium: {
    marginBottom: '25px',
  },
  spaceTopLarge: {
    marginBottom: '50px',
  },
  spaceTopExtraLarge: {
    marginBottom: '65px',
  },
  noteDetailsContainerRow: {
    // marginTop: '2px',
    display: 'flex',
    flexDirection: 'row',
  },
  noteContainerBordered: {
    borderBottom: '1px solid grey',
  },
  noteDetailsContainerColumn: {
    margin: '2px',
    display: 'flex',
    flexDirection: 'column',
  },
  editContainer: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid grey',
    margin: 5,
  },
  editSubContainer: {
    display: 'flex',
    width: '45%',
    flexDirection: 'column',
    borderRight: '1px solid grey',
    marginTop: 5,
    marginBottom: 5,
  },
  editContainerHeader: {
    display: 'flex',
    flexDirection: 'row',
    margin: 5,
  },
  editHeaderHeading: {
    fontSize: 12,
  },
  editHeaderValue: {
    fontSize: 13,
    height: 20,
  },
  fieldNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: 10,
  },
  fieldNameHeading: {
    width: '40%',
    marginRight: 5,
    fontSize: 13,
  },
  fieldNameValue: {
    textAlign: 'left',
    marginLeft: 5,
    color: 'green',
    fontSize: 12,
  },
  changesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginLeft: 10,
  },
  changeValuePair: {
    display: 'flex',
    width: '50%',
    flexDirection: 'row',
    columnGap: '5px',
  },
  changeHeading: {
    color: 'black',
    fontSize: 13,
  },
  changeValue: {
    color: 'green',
    fontSize: 12,
    maxWidth: '40%',
  },
});

const tableStyles = StyleSheet.create({
  page: {
    padding: 15,
    fontFamily: 'Helvetica',
  },
  headerHeading: {
    color: 'green',
    marginRight: '10px',
    fontSize: 10,
    fontWeight: 800,
    marginLeft: '0px',
    marginTop: '5px',
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: '#343a40',
    fontSize: 10,
    borderRadius: 4,
    margin: '10px 0',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#343a40',
    fontSize: 10,
  },
  tableHeader: {
    flexDirection: 'row',
    backgroundColor: '#2f7d32c2',
    color: '#fff',
    fontWeight: 700,
    borderBottomWidth: 1,
    borderColor: '#343a40',
    fontSize: 11,
  },
  serviceCell: {
    flex: 4,
    borderRightWidth: 1,
    borderColor: '#343a40',
    textAlign: 'left',
    padding: 5,
  },
  cell: {
    flex: 1,
    borderRightWidth: 1,
    borderColor: '#343a40',
    textAlign: 'center',
    padding: 5,
    textTransform: 'capitalize',
  },
  lastCell: {
    flex: 1,
    textAlign: 'center',
    padding: 5,
  },
  overallRow: {
    flexDirection: 'row',
    fontSize: 10,
    fontWeight: 700,
    backgroundColor: "#E0E0E0",
  },
  totalLabel: {
    fontSize: 11,
    fontWeight: "800",
    padding: "7px 0",
    marginRight: "5px",
    textAlign: "left",
    padding: 7,
    flex: 5,
  },
  totalValue: {
    fontSize: 10,
    fontWeight: "800",
    padding: 7,
    flex: 1,
    textAlign: "center",
  },
});

export default function SpillPdf({ data, userMetaData, notesSummary, totalTally }) {
  const getTotal = (key) => {
    let count = 0;
    for (let note of data?.data?.spill?.spill_notes) {
      count += parseFloat(note[key]) || 0;
    }
    return count;
  };

  const timeStampFlag = userMetaData
    ? canSeeStampsOrAmounts(
      userMetaData?.data?.role?.role,
      userMetaData?.data?.client_organization?.timestamp_visibility
    )
    : true;

  const monetaryFlag = userMetaData
    ? canSeeStampsOrAmounts(
      userMetaData?.data?.role?.role,
      userMetaData?.data?.client_organization?.monetary_visibility
    )
    : true;

  const header = (
    <View style={styles.row}>
      <View style={styles.colMd}>
        <View style={styles.mainHeader}>
          <View style={styles.mainHeaderContainer}>
            <Text style={styles.logo}>PES</Text>
            <View style={styles.mainHeaderSubContainer}>
              <Text style={styles.subHeading}>Spills</Text>
              <Text style={styles.subHeadingDesc}>
                Managed by Premium Environmental Services
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.colSm}>
        <View style={styles.row}>
          <View style={styles.noteDetailsContainerRow}>
            <Text style={styles.headerValueCustom}>
              <Text style={styles.headerValueKeyRegular}>
                <Text style={styles.textGreen}>Claim No:</Text>
              </Text>{' '}
              {data?.data?.spill?.claim_no || 'N/A'}
            </Text>
          </View>
        </View>      
        <View style={styles.row}>
          <View style={styles.noteDetailsContainerRow}>
            <Text style={styles.headerValueCustom}>
              <Text style={styles.headerValueKeyRegular}>
                <Text style={styles.textGreen}>Spill:</Text>
              </Text>{' '}
              {data?.data?.spill?.job_no || 'N/A'}
            </Text>
          </View>
        </View>
        <View style={styles.noteDetailsContainerColumn}>
          <Text style={styles.headerValueCustom}>
            <Text style={styles.headerValueKeyRegular}>
              <Text style={styles.textGreen}>Opened on:</Text>
            </Text>{' '}
            {getFormattedTime(data?.data?.spill?.opened_on) || 'N/A'}
          </Text>
          {data?.data?.spill?.closed_on && (
            <Text style={styles.headerValueCustom}>
              <Text style={styles.headerValueKeyRegular}>
                <Text style={styles.textGreen}>Closed On:</Text>
              </Text>{' '}
              {getFormattedTime(data?.data?.spill?.closed_on) || 'N/A'}
            </Text>
          )}
        </View>
      </View>
    </View>
  );

  return (
    <Document>
      <Page style={styles.page}>
        {header}

        <Text style={styles.sectionTitle}>Overview</Text>
        <View style={styles.noteContainer}>
          <View style={styles.row}>
            <View style={styles.col}>
              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>
                  Client Organization Details:{' '}
                </Text>
                {/* <View style={styles.noteDetailsContainerRow}> */}
                <Text style={styles.headerValue}>
                  <Text style={styles.headerValueKeyBold}>Name:</Text>{' '}
                  {data?.data?.spill?.client_organization?.name || 'N/A'}
                </Text>
                <Text style={styles.headerValue}>
                  <Text style={styles.headerValueKeyBold}>
                    Organization Address:
                  </Text>{' '}
                  {data?.data?.spill?.client_organization?.city || 'N/A'},
                  {data?.data?.spill?.client_organization?.state || 'N/A'},{' '}
                  {data?.data?.spill?.client_organization?.country || 'N/A'}
                </Text>
                <Text style={styles.headerValue}>
                  <Text style={styles.headerValueKeyBold}>Tax ID:</Text>{' '}
                  {data?.data?.spill?.client_organization?.tax_id || 'N/A'}
                </Text>
                {/* </View> */}
              </View>

              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>Managed By: </Text>
                <View style={styles.noteDetailsContainerRow}>
                  <Text style={styles.headerValue}>
                    <Text style={styles.headerValueKeyBold}>User:</Text>{' '}
                    {data?.data?.spill?.user?.full_name || 'N/A'}
                  </Text>
                  <Text style={styles.headerValue}>
                    <Text style={styles.headerValueKeyBold}>Email:</Text>{' '}
                    {data?.data?.spill?.user?.email || 'N/A'}
                  </Text>
                </View>
              </View>

              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>Status: </Text>
                <View style={styles.noteDetailsContainerRow}>
                  <Text style={styles.headerValue}>
                    <Text style={styles.headerValueKeyBold}>Status:</Text>{' '}
                    {data?.data?.spill?.status || 'N/A'}
                  </Text>
                </View>
              </View>

              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>Type: </Text>
                <View style={styles.noteDetailsContainerRow}>
                  <Text style={styles.headerValue}>
                    {data?.data?.spill?.type || 'N/A'}
                  </Text>
                </View>
              </View>

              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>Site Conditions: </Text>
                <View style={styles.noteDetailsContainerRow}>
                  <Text style={styles.headerValue}>
                    {data?.data?.spill?.conditions || 'N/A'}
                  </Text>
                </View>
              </View>

              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>Spill Address: </Text>
                <View style={styles.noteDetailsContainerRow}>
                  <Text style={styles.headerValue}>
                    {data?.data?.spill?.address || 'N/A'}
                  </Text>
                  <Text style={styles.headerValue}>
                    <Text style={styles.headerValueKeyBold}>
                      Location Type:
                    </Text>{' '}
                    {data?.data?.spill?.location_type || 'N/A'}
                  </Text>
                </View>
              </View>

              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>Point of Contact: </Text>
                <View style={styles.noteDetailsContainerRow}>
                  <Text style={styles.headerValue}>
                    {data?.data?.spill?.contact || 'N/A'}
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.col}>
              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>Responsible Party: </Text>
                <View style={styles.noteDetailsContainerRow}>
                  <Text style={styles.headerValue}>
                    {data?.data?.spill?.responsible || 'N/A'}
                  </Text>
                </View>
              </View>

              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>GPS Coordinates: </Text>
                <View style={styles.noteDetailsContainerRow}>
                  <Text style={styles.headerValue}>
                    <Text style={styles.headerValueKeyBold}>Latitude:</Text>{' '}
                    {data?.data?.spill?.latitude || 'N/A'}
                  </Text>
                  <Text style={styles.headerValue}>
                    <Text style={styles.headerValueKeyBold}>Longitude:</Text>{' '}
                    {data?.data?.spill?.longitude || 'N/A'}
                  </Text>
                </View>
              </View>

              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>Impacts: </Text>
                <View style={styles.noteDetailsContainerRow}>
                  <Text style={styles.headerValue}>
                    <Text style={styles.headerValueKeyBold}>
                      Drain Impacted:
                    </Text>{' '}
                    {data?.data?.spill?.drain_impacted || 'N/A'}
                  </Text>
                  <Text style={styles.headerValue}>
                    <Text style={styles.headerValueKeyBold}>
                      Waterway Impacted:
                    </Text>{' '}
                    {data?.data?.spill?.waterway_impacted || 'N/A'}
                  </Text>
                </View>
              </View>

              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>Driver: </Text>
                <View style={styles.noteDetailsContainerRow}>
                  <Text style={styles.headerValue}>
                    <Text style={styles.headerValueKeyBold}>Name:</Text>{' '}
                    {data?.data?.spill?.driver_name || 'N/A'}
                  </Text>
                  <Text style={styles.headerValue}>
                    <Text style={styles.headerValueKeyBold}>Phone:</Text>{' '}
                    {data?.data?.spill?.driver_phone || 'N/A'}
                  </Text>
                </View>
              </View>

              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>Onsite POC: </Text>
                <View style={styles.noteDetailsContainerRow}>
                  <Text style={styles.headerValue}>
                    <Text style={styles.headerValueKeyBold}>Name:</Text>{' '}
                    {data?.data?.spill?.onsite_poc_name || 'N/A'}
                  </Text>
                  <Text style={styles.headerValue}>
                    <Text style={styles.headerValueKeyBold}>Phone:</Text>{' '}
                    {data?.data?.spill?.onsite_poc_phone || 'N/A'}
                  </Text>
                </View>
              </View>

              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>Tractor: </Text>
                <View style={styles.noteDetailsContainerRow}>
                  <Text style={styles.headerValue}>
                    {data?.data?.spill?.tractor || 'N/A'}
                  </Text>
                </View>
              </View>

              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>Trailer: </Text>
                <View style={styles.noteDetailsContainerRow}>
                  <Text style={styles.headerValue}>
                    {data?.data?.spill?.trailer || 'N/A'}
                  </Text>
                </View>
              </View>

              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>Pro: </Text>
                <View style={styles.noteDetailsContainerRow}>
                  <Text style={styles.headerValue}>
                    {data?.data?.spill?.pro || "N/A"}
                  </Text>
                </View>
              </View>

              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>Dock Door Number: </Text>
                <View style={styles.noteDetailsContainerRow}>
                  <Text style={styles.headerValue}>
                    {data?.data?.spill?.dock_door_number || "N/A"}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>

      <Page style={styles.page}>
        {header}

        <Text style={styles.sectionTitle}>Connections</Text>
        <View style={styles.noteContainer}>
          <View style={styles.row}>
            <View style={styles.col}>
              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>Agency: </Text>
                <View style={styles.noteDetailsContainerRow}>
                  <Text style={styles.headerValue}>
                    <Text style={styles.headerValueKeyBold}>Name:</Text>{' '}
                    {data?.data?.connection?.agency?.name || 'N/A'}
                  </Text>
                  <Text style={styles.headerValue}>
                    <Text style={styles.headerValueKeyBold}>Address:</Text>{' '}
                    {data?.data?.connection?.agency?.address || 'N/A'}
                  </Text>
                  <Text style={styles.headerValue}>
                    <Text style={styles.headerValueKeyBold}>City:</Text>{' '}
                    {data?.data?.connection?.agency?.city || 'N/A'}
                  </Text>
                  <Text style={styles.headerValue}>
                    <Text style={styles.headerValueKeyBold}>State:</Text>{' '}
                    {data?.data?.connection?.agency?.state || 'N/A'}
                  </Text>
                  <Text style={styles.headerValue}>
                    <Text style={styles.headerValueKeyBold}>Phone:</Text>{' '}
                    {data?.data?.connection?.agency?.phone || 'N/A'}
                  </Text>
                  <Text style={styles.headerValue}>
                    <Text style={styles.headerValueKeyBold}>Fax:</Text>{' '}
                    {data?.data?.connection?.agency?.fax || 'N/A'}
                  </Text>
                </View>
                <View style={styles.spaceBottomSmall} />
              </View>

              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>National Incident No: </Text>
                <View style={styles.noteDetailsContainerRow}>
                  <Text style={styles.headerValue}>
                    {data?.data?.connection?.incident_no || 'N/A'}
                  </Text>
                </View>
                <View style={styles.spaceBottomSmall} />
              </View>

              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>State Incident No: </Text>
                <View style={styles.noteDetailsContainerRow}>
                  <Text style={styles.headerValue}>
                    {data?.data?.connection?.state_incident_no || 'N/A'}
                  </Text>
                </View>
                <View style={styles.spaceBottomSmall} />
              </View>

              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>Contractors: </Text>
                {data?.data?.spillContractors?.map((contractor) => (
                  <>
                    <View style={styles.noteDetailsContainerColumn}>
                      <Text style={styles.headerValue}>
                        <Text style={styles.headerValueKeyBold}>
                          {contractor?.label || 'N/A'}:{' '}
                        </Text>
                      </Text>
                      <Text style={styles.headerValue}>
                        <Text style={styles.headerValueKeyRegular}>
                          Address:
                        </Text>{' '}
                        {contractor?.address || 'N/A'}
                      </Text>
                      <Text style={styles.headerValue}>
                        {contractor?.city || 'N/A'},{' '}
                        {contractor?.state || 'N/A'},{' '}
                        {contractor?.country || 'N/A'}
                      </Text>
                      <Text style={styles.headerValue}>
                        <Text style={styles.headerValueKeyRegular}>Phone:</Text>{' '}
                        {contractor?.phone || 'N/A'}
                      </Text>
                      <Text style={styles.headerValue}>
                        <Text style={styles.headerValueKeyRegular}>Fax:</Text>{' '}
                        {contractor?.fax || 'N/A'}
                      </Text>
                      <Text style={styles.headerValue}>
                        <Text style={styles.headerValueKeyRegular}>Email:</Text>{' '}
                        {contractor?.email || 'N/A'}
                      </Text>
                    </View>
                    <View style={styles.spaceBottomExtraLarge} />
                  </>
                ))}
              </View>
            </View>
          </View>
        </View>
      </Page>

      <Page style={styles.page}>
        {header}

        <Text style={styles.sectionTitle}>Miscellany</Text>
        <View style={styles.noteContainer}>
          <View style={styles.row}>
            <View style={styles.col}>
              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>Job No: </Text>
                <View style={styles.noteDetailsContainerRow}>
                  <Text style={styles.headerValue}>
                    {data?.data?.spill?.job_no || 'N/A'}
                  </Text>
                </View>
                <View style={styles.spaceBottomSmall} />
              </View>
              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>Material Class: </Text>
                <View style={styles.noteDetailsContainerRow}>
                  <Text style={styles.headerValue}>
                    {data?.data?.spill?.material_class || 'N/A'}
                  </Text>
                </View>
                <View style={styles.spaceBottomSmall} />
              </View>

              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>Shipper Name: </Text>
                <View style={styles.noteDetailsContainerRow}>
                  <Text style={styles.headerValue}>
                    {data?.data?.spill?.shipper_name || "N/A"}
                  </Text>
                </View>
                <View style={styles.spaceBottomSmall} />
              </View>
              <View style={styles.subNoteContainer}>

                <Text style={styles.headerHeading}>Amount Released: </Text>
                <View style={styles.noteDetailsContainerRow}>
                  <Text style={styles.headerValue}>
                    {data?.data?.spill?.amount_released || 'N/A'}
                  </Text>
                </View>
                <View style={styles.spaceBottomSmall} />
              </View>

              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>
                  Actual  Quantity Type Released:
                </Text>
                <View style={styles.noteDetailsContainerRow}>
                  <Text style={styles.headerValue}>
                    {data?.data?.spill?.actual_quantity_type_released || "N/A"}
                  </Text>
                </View>
                <View />
              </View>
              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>
                  Damaged Container Type:{' '}
                </Text>
                <View style={styles.noteDetailsContainerRow}>
                  <Text style={styles.headerValue}>
                    {data?.data?.spill?.damaged_container_type || 'N/A'}
                  </Text>
                </View>
                <View />
              </View>

            </View>

            <View style={styles.col}>
           
              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>Dates: </Text>
                <View style={styles.noteDetailsContainerRow}>
                  <Text style={styles.headerValue}>
                    <Text style={styles.headerValueKeyBold}>Opened on: </Text>
                    {getFormattedTime(data?.data?.spill?.opened_on) || 'N/A'}
                  </Text>
                  <Text style={styles.headerValue}>
                    <Text style={styles.headerValueKeyBold}>Closed on: </Text>
                    {getFormattedTime(data?.data?.spill?.closed_on) || 'N/A'}
                  </Text>
                </View>
                <View style={styles.spaceBottomSmall} />
              </View>

              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>Material: </Text>
                <View style={styles.noteDetailsContainerRow}>
                  <Text style={styles.headerValue}>
                    {data?.data?.spill?.spill_material?.name || 'N/A'}
                  </Text>
                </View>
                <View style={styles.spaceBottomSmall} />
              </View>

              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>Shipper Address: </Text>
                <View style={styles.noteDetailsContainerRow}>
                  <Text style={styles.headerValue}>
                    {data?.data?.spill?.shipper_address || "N/A"}
                  </Text>
                </View>
                <View style={styles.spaceBottomSmall} />
              </View>
              
              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>
                  Quantity Type Released:{' '}
                </Text>
                <View style={styles.noteDetailsContainerRow}>
                  <Text style={styles.headerValue}>
                    {data?.data?.spill?.quantity_type_released || 'N/A'}
                  </Text>
                </View>
                <View style={styles.spaceBottomSmall} />
              </View>
             
              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>Actual Amount Released: </Text>
                <View style={styles.noteDetailsContainerRow}>
                  <Text style={styles.headerValue}>
                    {data?.data?.spill?.actual_amount_released || "N/A"}
                  </Text>
                </View>
                <View />
              </View>
            </View>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.col}>
            <View style={styles.subNoteContainer}>
              <Text style={styles.headerHeading}>Type of Damaged: </Text>
              <View style={styles.noteDetailsContainerRow}>
                <Text style={styles.headerValue}>
                  {data?.data?.spill?.damaged_type || 'N/A'}
                </Text>
              </View>
              <View style={styles.spaceBottomSmall} />
            </View>

            <View style={styles.subNoteContainer}>
              <Text style={styles.headerHeading}>Claim No.: </Text>
              <View style={styles.noteDetailsContainerRow}>
                <Text style={styles.headerValue}>
                  {data?.data?.spill?.claim_no || 'N/A'}
                </Text>
              </View>
              <View style={styles.spaceBottomSmall} />
            </View>

            <View style={styles.subNoteContainer}>
              <Text style={styles.headerHeading}>UN No.: </Text>
              <View style={styles.noteDetailsContainerRow}>
                <Text style={styles.headerValue}>
                  {data?.data?.spill?.un_no || 'N/A'}
                </Text>
              </View>
              <View style={styles.spaceBottomSmall} />
            </View>
            <View style={styles.subNoteContainer}>
              <Text style={styles.headerHeading}>Total Impermeabale Area: </Text>
              <View style={styles.noteDetailsContainerRow}>
                <Text style={styles.headerValue}>
                  {data?.data?.spill?.spill_notes_data?.total_impermeable_area || "N/A"}
                </Text>
              </View>
              <View style={styles.spaceBottomSmall} />
            </View>
          </View>

          <View style={styles.col}>
            <View style={styles.subNoteContainer}>
              <Text style={styles.headerHeading}>Toggles: </Text>
              <View style={styles.noteDetailsContainerColumn}>
                <Text style={styles.headerValue}>
                  <Text style={styles.headerValueKeyBold}>Need Map? </Text>
                  {data?.data?.spill?.map_needed ? 'Yes' : 'No'}
                </Text>
                <Text style={styles.headerValue}>
                  <Text style={styles.headerValueKeyBold}>Need 5800? </Text>
                  {data?.data?.spill?.need_5800 ? 'Yes' : 'No'}
                </Text>
                <Text style={styles.headerValue}>
                  <Text style={styles.headerValueKeyBold}>Is Waste? </Text>
                  {data?.data?.spill?.is_waste ? 'Yes' : 'No'}
                </Text>
                <Text style={styles.headerValue}>
                  <Text style={styles.headerValueKeyBold}>Has Msds? </Text>
                  {data?.data?.spill?.has_msds ? 'Yes' : 'No'}
                </Text>
                <Text style={styles.headerValue}>
                  <Text style={styles.headerValueKeyBold}>Is Hazmat? </Text>
                  {data?.data?.spill?.is_hazmat ? 'Yes' : 'No'}
                </Text>
                <Text style={styles.headerValue}>
                  <Text style={styles.headerValueKeyBold}>Response Sent? </Text>
                  {data?.data?.spill?.response_sent ? "Yes" : "No"}
                </Text>
                <Text style={styles.headerValue}>
                  <Text style={styles.headerValueKeyBold}>
                    Potential Subrogation?{" "}
                  </Text>
                  {data?.data?.spill?.subrogation ? "Yes" : "No"}
                </Text>
                <Text style={styles.headerValue}>

                  <Text style={styles.headerValueKeyBold}>Released Outside of building or Trailer? </Text>
                  {data?.data?.spill?.released_outside_building ? "Yes" : "No"}
                </Text>
                <Text style={styles.headerValue}>
                  <Text style={styles.headerValueKeyBold}>Release Made it offsite? </Text>
                  {data?.data?.spill?.release_offsite ? "Yes" : "No"}
                </Text>
              </View>
              {/* <View style={styles.spaceBottomSmall} /> */}
            </View>
            <View>
              <Text style={styles.headerHeading}>Total Permeabale Area: </Text>
              <View style={styles.noteDetailsContainerRow}>
                <Text style={styles.headerValue}>
                  {data?.data?.spill?.spill_notes_data?.total_permeable_area || "N/A"}
                </Text>
              </View>
              <View style={styles.spaceBottomSmall} />
            </View>


            <View style={styles.subNoteContainer}>
              <Text style={styles.headerHeading}>Total Area Covered By Release: </Text>
              <View style={styles.noteDetailsContainerRow}>
                <Text style={styles.headerValue}>
                  {(Number(data?.data?.spill?.spill_notes_data?.total_permeable_area) + Number(data?.data?.spill?.spill_notes_data?.total_impermeable_area)) || "N/A"}
                </Text>
              </View>
              <View style={styles.spaceBottomSmall} />
            </View>
          </View>
        </View>
      </Page>

      <Page style={styles.page}>
        {header}
        <Text style={styles.sectionTitle}>Notes & Attachments</Text>
        <View style={styles.noteContainer}>
          <View style={styles.row}>
            <View style={styles.col}>
              <View style={styles.subNoteContainer}>
                <Text style={styles.headerHeading}>Notes: </Text>
                {data?.data?.spill?.spill_notes?.map((note) => (
                  <>
                    <View style={styles.noteDetailsContainerColumn}>
                      <View style={styles.noteDetailsContainerRow}>
                        <View style={styles.noteDetailsContainerColumn}>
                          {timeStampFlag && (
                            <Text style={styles.headerValue}>
                              {Moment(new Date(note?.created_at))?.format(
                                'MM-DD-YYYY h:mm a'
                              )}
                            </Text>
                          )}

                          {monetaryFlag && (
                            <>
                              <Text style={styles.headerValue}>
                                <Text style={styles.headerValueKeyRegular}>
                                  {`${note?.hour}h @ $${note.rate}/h`}
                                </Text>
                              </Text>
                              <Text style={styles.headerValue}>
                                <Text style={styles.headerValueKeyRegular}>
                                  {`$${note?.amount}`}
                                </Text>
                              </Text>
                            </>
                          )}
                        </View>

                        <View style={styles.noteDetailsContainerColumn}>
                          <Text style={styles.headerValue}>
                            <Text style={styles.headerValueKeyRegular}>
                              <Text style={styles.textGreen}>
                                {note?.service_type}
                              </Text>
                            </Text>
                          </Text>
                          <Text style={styles.headerValue}>
                            <Text style={styles.headerValueKeyRegular}>
                              {note?.description}
                            </Text>
                          </Text>
                        </View>
                      </View>
                    </View>
                  </>
                ))}
              </View>
            </View>
          </View>
        </View>
      </Page>

      {notesSummary?.length > 0 && 
      <Page style={styles.page}>
        {header}
        <Text style={styles.sectionTitle}>Notes Summary</Text>
        <View style={styles.noteContainer}>
          {(timeStampFlag || monetaryFlag) && (
            <View style={tableStyles.page}> 
              {/* <Text style={tableStyles.headerHeading}>Notes Summary:</Text> */}
              <View style={tableStyles.table}>
                <View style={tableStyles.tableHeader}>
                  <Text style={tableStyles.serviceCell}>Service</Text>
                  <Text style={tableStyles.cell}>Status</Text>
                  <Text style={tableStyles.cell}>Tally</Text>
                  <Text style={tableStyles.cell}>Total Hours</Text>
                  <Text style={tableStyles.lastCell}>Cost ($)</Text>
                </View>
                {notesSummary.map((note, index) => (
                  <View key={index} style={tableStyles.tableRow}>
                    <Text style={tableStyles.serviceCell}>{note?.service}</Text>
                    <Text style={tableStyles.cell}>{note?.status}</Text>
                    <Text style={tableStyles.cell}>{note?.tally}</Text>
                    <Text style={tableStyles.cell}>{(note?.totalHours)?.toFixed(2)}</Text>
                    <Text style={tableStyles.lastCell}>${(note?.cost)?.toFixed(2)}</Text>
                  </View>
                ))}
                <View style={tableStyles.overallRow}>
                  <Text style={tableStyles.totalLabel}>OVERALL TOTAL</Text>
                  <Text style={tableStyles.totalValue}>{totalTally?.toString().padStart(2, '0')}</Text>
                  <Text style={tableStyles.totalValue}>{`${getTotal('hour')?.toFixed(2)} hours`}</Text>
                  <Text style={tableStyles.totalValue}>${getTotal('amount')?.toFixed(2)}</Text>
                </View>
              </View>
            </View>
          )}
        </View>
      </Page>}
    </Document>
  );
}
