const initialState = {
  success: false,
  loading: false,
  error: null,
  downloadType: null,
  dataForDownload: {
    data: [],
    loading: false,
    error: null,
  },
  signedUrlsList: {
    data: [],
    loading: false,
    error: null,
    success: null,
  },
};

const downloadReducer = (
  state = initialState,
  { obj, type, error, payload, data, pagination }
) => {
  switch (type) {
    case "GET_SIGNED_URL":
      return {
        ...initialState,
        type,
        loading: true,
        signedUrlsList: {
          ...state.signedUrlsList,
          loading: true,
          success: null,
        },
      };

    case "GET_SIGNED_URL_SUCCESS":
      return {
        ...state,
        loading: false,
        signedUrlsList: {
          ...state.signedUrlsList,
          data: data.data,
          loading: false,
        },
      };

    case "GET_SIGNED_URL_FAILURE":
      return {
        ...state,
        loading: false,
        signedUrlsList: {
          ...state.signedUrlsList,
          error: error,
          loading: false,
          success: false,
        },
      };
    case "DOWNLOAD":
      return {
        ...initialState,
        dataForDownload: null,
        type,
        loading: true,
      };

    case "DOWNLOAD_SUCCESS":
      return {
        ...state,
        success: true,
        loading: false,
        type,
        payload,
        downloadType: data.type,
        dataForDownload: {
          ...state.dataForDownload,
          data: data.data,
        },
      };

    case "DOWNLOAD_FAILURE":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case "DOWNLOAD_REVENUE_REPORT":
      return {
        ...initialState,
        dataForDownload: null,
        type,
        loading: true,
      };

    case "DOWNLOAD_REVENUE_REPORT_SUCCESS":
      return {
        ...state,
        success: true,
        loading: false,
        type,
        payload,
        downloadType: data.type,
        dataForDownload: {
          ...state.dataForDownload,
          data: data.data,
        },
      };
    
    case "DOWNLOAD_REVENUE_REPORT_FAILURE":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case "CLEAR_DOWNLOAD":
      return {
        ...state,
        success: true,
        loading: false,
        type,
        payload,
        downloadType: null,
        dataForDownload: {
          data: [],
          loading: false,
          error: null,
        },
      };

    case "DOWNLOAD_FILE_TEMP":
      return {
        ...state,
        loading: data.data.isCertificate ? false : true,
      };
    case "DOWNLOAD_FILE":
      return {
        ...state,
        loading: true,
      };

    case "DOWNLOAD_FILE_SUCCESS":
      return {
        ...state,
        success: true,
        loading: false,
      };

    case "DOWNLOAD_FILE_FAIL":
      return {
        ...state,
        error,
        loading: false,
        success: false,
      };

    case "DOWNLOAD_ALL":
      return {
        ...state,
        loading: true,
      };

    case "DOWNLOAD_ADMIN_FILES":
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
};

export default downloadReducer;
