import { PDFDocument } from "pdf-lib";

// escape special characters
export default function escapeRegExp(string) {
  return string?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export const formatCurrencyToUSD = (amount) => {
  amount = amount?.replace(/,/g, '');
  return Number(amount)?.toLocaleString('en-US', {
    minimumFractionDigits: 2
  });
};

export const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));

// Function to check if PDF is valid using pdf-lib
export const isPdfValid = async (file) => {
  try {
    const arrayBuffer = await file.arrayBuffer();
    await PDFDocument.load(arrayBuffer); // Try to load the PDF
    return true; // Valid PDF
  } catch (error) {
    return false; // Invalid PDF
  }
};