/** @format */

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactSelect from 'react-select';
import { useForm } from 'react-hook-form';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import moment from 'moment';
import MomentUtils from '@date-io/moment';

import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import {
  Grid,
  Radio,
  Button,
  Select,
  Checkbox,
  MenuItem,
  FormGroup,
  Container,
  TextField,
  Typography,
  InputLabel,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import * as userActions from '../../../../../actionCreators/User';
import * as spillActions from '../../../../../actionCreators/Spill';
import * as agencyActions from '../../../../../actionCreators/Agency';
import * as clientActions from '../../../../../actionCreators/Client';
import * as servicesActions from '../../../../../actionCreators/Service';
import * as contractorAction from '../../../../../actionCreators/Contractor';

import States from '../DropDownData/states.json';
import {
  getContractorOptions,
  convertToGroupedOptions,
  contractorColourStyles as colourStyles,
  orderAlphabaticallyByKey,
  filterOption,
  calculateContractors,
  orderAlphabatically,
  quantityTypes,
  damagedContainerTypes,
  damageTypes,
  ROLES,
  isContractorUser,
  isCorporateUser,
  isPESUser,
  states,
  forceToCST,
} from '../../../../../utils';
import Switch from '@material-ui/core/Switch';
import { CustomProgressLoader } from '../../../../../Components';
import { COUNTRIES, USER_TYPE } from '../../../../../utils/keyMappers';

const useStyles = makeStyles((theme) => ({
  root: {
    '&$checked': {
      color: '#2F7D32',
    },
  },
  checked: {},
  avatar: {
    backgroundColor: green[500],
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  hide: {
    visibility: 'none',
  },
  clearSearchButton: {
    textAlign: 'right',
  },
  alignLeft: {
    textAlign: 'left',
  },
  checkboxColor: {
    color: '#2F7D32',
  },
  radioGroupDisplay: {
    display: 'inline',
  },
  leftAlign: {
    textAlign: 'left',
  },
}));

const SearchSpill = ({
  users,
  admins,
  history,
  loading,
  agencies,
  getAdmins,
  goToSpills,
  reportType,
  getAgencies,
  getServices,
  currentUser,
  getStatuses,
  updateStatus,
  searchSpills,
  reportsAllow,
  defaultSearch,
  clientChildren,
  setSpillFilters,
  packetReviewers,
  batchUpdateAllow,
  getUsersForEmail,
  subrogationAllow,
  pendingDisposalAllow,
  setSelectedSpills,
  spillFiltersState,
  organizationAdmins,
  fetchPacketReviewers,
  switchReportsHandler,
  getOrganizationNames,
  setSpillDataForFilter,
  getOrganizationAdmins,
  contractorsWithAddress,
  clientOrganizationNames,
  getOrganizationChildren,
  setFinalSpillsSearchData,
  getContractorsWithAddress,
  getMaterialClass,
  materialClass,
  isShipperReport
}) => {
  const classes = useStyles();

  const location = history?.location;
  const shouldHideFields = location.pathname === '/dashboard/reports/pendingdisposal';

  const formRef = useRef();
  const { handleSubmit, register } = useForm();

  const userRole = currentUser?.data?.role?.role;

  document.title = 'Search';

  const [selectedOrganizations, setSelectedOrganizations] = React.useState([]);
  const [selectedManagers, setSelectedManagers] = React.useState([]);
  const [selectedUsersForEmail, setSelectedUsersForEmail] = React.useState([]);
  const [selectedPacketReview, setSelectedPacketReview] = React.useState([]);
  const [selectedContractors, setSelectedContractors] = React.useState([]);
  const [selectedRejectContractors, setSelectedRejectContractors] =
    React.useState([]);
  const [canSeeChildOrg, setCanSeeChildOrg] = React.useState(false);
  const [filterOrganizations, setFilterOrganizations] = React.useState([]);
  const [selectedChildOrganizations, setSelectedChildOrganizations] =
    React.useState([]);
  const [childOrganizations, setChildOrganizations] = React.useState([]);

  const [selectedAgencies, setSelectedAgencies] = React.useState([]);
  const [contractorOptions, setContractorOptions] = React.useState();
  const [organizationFilter, setOrganizationFilter] = React.useState('');
  const [childOrganizationFilter, setChildOrganizationFilter] =
    React.useState('');
  const [managerFilter, setManagerFilter] = React.useState('');
  const [usersFilter, setUsersFilter] = React.useState('');
  const [packetReviewFilter, setPacketReviewFilter] = React.useState('');
  const [agenciesFilter, setAgenciesFilter] = React.useState('');
  const [isAllAgenciesSelected, setIsAllAgenciesSelected] =
    React.useState(false);
  const [contractorsAccepFilter, setContractorsAccepFilter] =
    React.useState('');
  const [contractorsRejFilter, setContractorsRejFilter] = React.useState('');
  const [selectedMaterialClasses, setSelectedMaterialClasses] = React.useState([]); //Selected Material Classes To Search
  const [materialClassData, setMaterialClassData] = React.useState([]); //Materical Class Data From API
  const [materialClassFilter, setMaterialClassFilter] = React.useState(''); // Holds the chars to search for in dropdown

  // Active Users
  const [isActiveUsers, setActiveUsers] = React.useState('');
  const [isActiveUsersCheckboxEnabled, setActiveUsersCheckboxEnabled] =
    React.useState(false);

  // Inactive Users
  const [isInactiveUsers, setInactiveUsers] = React.useState('');
  const [isInactiveUsersCheckboxEnabled, setInactiveUsersCheckboxEnabled] =
    React.useState(false);

  // Active Managers
  const [isActiveManagers, setActiveManagers] = React.useState('');
  const [isActiveManagersCheckboxEnabled, setActiveManagersCheckboxEnabled] =
    React.useState(false);

  // Inactive Managers
  const [isInactiveManagers, setInactiveManagers] = React.useState('');
  const [
    isInactiveManagersCheckboxEnabled,
    setInactiveManagersCheckboxEnabled,
  ] = React.useState(false);

  // Packet Reviewers
  const [fetchedPacketReviewersData, setFetchedPacketReviewersData] =
    React.useState({
      data: [],
      loading: false,
      success: false,
      error: null,
    });

  const [status, setStatus] = React.useState({
    open: { value: false, label: 'Open: Open', name: 'open' },
    work_in_progress: {
      value: false,
      label: 'Open: Work in Progress',
      name: 'work_in_progress',
    },
    site_work_complete: {
      value: false,
      label: 'Open: Site Work Complete',
      name: 'site_work_complete',
    },
    extended_remediation: {
      value: false,
      label: 'Open: Extended Remediation',
      name: 'extended_remediation',
    },
    pending_excavation: {
      value: false,
      label: 'Open: Pending Excavation',
      name: 'pending_excavation',
    },
    documentation_in_review: {
      value: false,
      label: 'Open: Documentation In Review',
      name: 'documentation_in_review',
    },
    pending_disposal: {
      value: false,
      label: 'Open: Pending Disposal',
      name: 'pending_disposal',
    },
    close: { value: false, label: 'Closed: any', name: 'close' },
    invoiced_but_not_paid: {
      value: false,
      label: 'Closed: Invoice Submitted to Client',
      name: 'invoiced_but_not_paid',
    },
    paid_but_contractor_not_yet_paid: {
      value: false,
      label: 'Closed: Payment Pending',
      name: 'paid_but_contractor_not_yet_paid',
    },
    documentation_sent_back_to_contractor_for_revision: {
      value: false,
      label: 'Open: Documentation Sent Back to Contractor for Revision',
      name: 'documentation_sent_back_to_contractor_for_revision',
    },
    ready_to_invoice: {
      value: false,
      label: 'Open: Ready to Invoice',
      name: 'ready_to_invoice',
    },
    final_review: {
      value: false,
      label: 'Open: Final Review',
      name: 'final_review',
    },
    ready_to_close: {
      value: false,
      label: 'Open: Ready to Close',
      name: 'ready_to_close',
    },
    closed: { value: false, label: 'Closed: Closed', name: 'closed' },
  });
  const [textFieldsData, setTextFieldsData] = React.useState({
    city: '',
    claim_no: '',
    conditions: '',
    contact: '',
    job_no: '',
    material: '',
    responsible: '',
    address: '',
    amount_released: null,
    tractor: null,
    trailer: null,
    pro: null,
    shipperName: null,
    shipperAddress: null
  });

  const [spillSelectData, setSpillSelectData] = React.useState({
    type: '',
    state: '',
    country: '',
    opened_on: null,
    opened_to: null,
    close_on: null,
    close_to: null,
    quantity_type_released: null,
    damaged_container_type: null,
    damaged_type: null,
    location_type: null,
    disposal_handled_by:null,
    container_disposition_type:null,
  });

  const [spillQuestions, setSpillQuestions] = React.useState({
    map_needed: {
      value: false,
      name: 'map_needed',
      key: 0,
      label: 'Map Needed?',
    },
    need_5800: {
      value: false,
      name: 'need_5800',
      key: 0,
      label: 'Need 5800?',
    },
    is_waste: { value: false, name: 'is_waste', key: 0, label: 'Is Waste?' },
    has_msds: { value: false, name: 'has_msds', key: 0, label: 'Has Msds?' },
    is_hazmat: {
      value: false,
      name: 'is_hazmat',
      key: 0,
      label: 'Is Hazmat?',
    },
    response_sent: {
      value: false,
      name: 'response_sent',
      key: 0,
      label: 'Response Sent?',
    },
    drain_impacted: {
      value: false,
      name: 'drain_impacted',
      key: 0,
      label: 'Drain Impacted?',
    },
    waterway_impacted: {
      value: false,
      name: 'waterway_impacted',
      key: 0,
      label: 'Waterway Impacted?',
    },
    is_emergency: {
      value: false,
      name: 'is_emergency',
      key: 0,
      label: 'Non Emergency',
    },
    haz_waste_hauler: {
      value: false,
      name: 'haz_waste_hauler',
      key: 0,
      label: 'Has Waste Hauler?',
    },
    subrogation: {
      value: defaultSearch ? false : true,
      name: 'subrogation',
      key: 1,
      label: 'Potential Subrogation',
    },
  });

  React.useEffect(() => {
    let newContractors = getContractorOptions(contractorsWithAddress?.data);
    if ([9, 10].includes(currentUser?.data?.role_id)) {
      newContractors = newContractors.filter(
        (singleContractor) =>
          singleContractor.name === currentUser?.data?.contractor?.name
      );
    }
    setContractorOptions(convertToGroupedOptions(newContractors));
  }, [contractorsWithAddress]);

  React.useEffect(() => {
    handleClearSearch();
  }, [location.key]);

  React.useEffect(() => {
    if (spillFiltersState?.data) {
      maintainFiltersHandler();
    }
  }, [spillFiltersState?.data]);

  React.useEffect(() => {
    if (!shouldHideFields) {
      getAgencies();
      getServices();
      getStatuses();
      getUsersForEmail();
      fetchPacketReviewers();
    }   
    getContractorsWithAddress({ withAttachmentExpiry: false });
    if (isCorporateUser(userRole)) {
      getOrganizationAdmins();
      getOrganizationChildren({ id: currentUser?.data?.org_id });
    } else {
      getOrganizationNames();
      getAdmins({ filter: 'all', scenario: 'search' });
    }
  }, [currentUser]);

  React.useEffect(() => {
    let child_org = [];
    let flatArray = [];
    let filterOrganization = [];
    if (spillFiltersState?.data?.canSeeChildOrg) {
      if (spillFiltersState?.data?.organizations) {
        for (const iterator of spillFiltersState?.data?.organizations) {
          let parent_id = iterator?.value;

          if (iterator?.associated_orgs) {
            child_org?.push(
              iterator?.associated_orgs?.map((ao) => {
                return {
                  label: ao?.client_organization?.name,
                  value: ao?.client_organization?.id,
                  parent: parent_id,
                };
              })
            );
            flatArray = [].concat(...child_org);

            flatArray = flatArray?.filter((item, index, arr) => {
              return (
                arr.findIndex((item2) => {
                  return item2.value === item?.value;
                }) === index
              );
            });

            setChildOrganizations(flatArray);
          }
        }

        const child_ids = flatArray?.map((org) => org?.value);
        if (flatArray.length > 0) {
          filterOrganization = organizations?.filter(
            (organization) => !child_ids?.includes(organization.value)
          );
        }
        setFilterOrganizations(filterOrganization);
      }
    }
  }, [spillFiltersState?.data?.organizations]);

  React.useEffect(() => {
    if (packetReviewers?.data?.length > 0) {
      setFetchedPacketReviewersData({
        ...fetchedPacketReviewersData,
        data: packetReviewers?.data,
        loading: packetReviewers?.loading,
        success: packetReviewers?.success,
      });
    }
  }, [packetReviewers]);

  // Dispatch getMaterialClass Action to fetch material class data from API
  React.useEffect(() => {
    getMaterialClass()
  },[])

  // Set and Manage the material class data with appropriate dropdown Options
  React.useEffect(() => {
    if(materialClass?.success) {
    const materialClassOptions = materialClass?.data?.map((materialClass) => {
      return {
        value: materialClass?.id, // Material Class Id
        label: materialClass?.name, // Material Class Name
      };
    });

    setMaterialClassData(materialClassOptions);
    }
  }, [materialClass?.success])
  

  const containsOnly = (checkingArray, dataArray) => {
    return dataArray?.every((elem) => checkingArray?.includes(elem));
  };

  const allCheck = (tempStatuses, type) => {
    let checkingOpen = false;
    let checkingClose = false;
    let allOpenCheckValues = [];
    let allCloseCheckValues = [];
    let statusData = tempStatuses;

    for (const [key, value] of Object?.entries(tempStatuses)) {
      if (value?.label?.search('Open: ') > -1) {
        if (value?.name !== 'open') {
          allOpenCheckValues?.push(value?.value);
        }
      }
      if (value?.label?.search('Closed: ') > -1) {
        if (value.name !== 'close') {
          allCloseCheckValues?.push(value?.value);
        }
      }
    }

    if (containsOnly([true], allOpenCheckValues)) {
      checkingOpen = allOpenCheckValues?.every((val, i, arr) => val === arr[0]);
    }

    if (containsOnly([true], allCloseCheckValues)) {
      checkingClose = allCloseCheckValues?.every(
        (val, i, arr) => val === arr[0]
      );
    }

    if (type === 'open') {
      if (checkingOpen === true) {
        statusData = {
          ...tempStatuses,
          ['open']: { value: true, label: 'Open: Open', name: 'open' },
        };
      } else {
        statusData = {
          ...tempStatuses,
          ['open']: { value: false, label: 'Open: Open', name: 'open' },
        };
      }
    }

    if (type === 'close') {
      if (checkingClose === true) {
        statusData = {
          ...tempStatuses,
          ['close']: { value: true, label: 'Closed: any', name: 'close' },
        };
      } else {
        statusData = {
          ...tempStatuses,
          ['close']: { value: false, label: 'Closed: any', name: 'close' },
        };
      }
    }

    setStatus({ ...statusData });
  };

  const handleChangeCheckbox = (event) => {
    let statusType = '';

    let tempStatus = {
      ...status,
      [event.target.name]: {
        value: event.target.checked,
        label: status[event.target.name].label,
        name: status[event.target.name].name,
      },
    };

    setStatus(tempStatus);

    if (status[event?.target.name]?.label?.search('Open: ') > -1) {
      statusType = 'open';
    } else {
      statusType = 'close';
    }

    allCheck(tempStatus, statusType);
  };

  const handleChildSelectedOrganizations = (event) => {
    if (event === null) {
      setSelectedChildOrganizations([]);
    } else {
      setSelectedChildOrganizations(event);
    }
  };

  const handleChildToggle = (event) => {
    let child_org = [];
    let flatArray = [];
    let filterSelectedOrg = [];
    if (event?.target?.checked) {
      if (selectedOrganizations?.length > 0) {
        for (const iterator of selectedOrganizations) {
          let parent_id = iterator?.value;
          if (iterator?.associated_orgs) {
            child_org?.push(
              iterator?.associated_orgs?.map((ao) => {
                return {
                  label: ao?.client_organization?.name,
                  value: ao?.client_organization?.id,
                  parent: parent_id,
                };
              })
            );

            flatArray = [].concat(...child_org);
            flatArray = flatArray?.filter((item, index, arr) => {
              return (
                arr.findIndex((item2) => {
                  return item2.value === item?.value;
                }) === index
              );
            });
            setChildOrganizations(flatArray);
          }
        }
        if (flatArray.length > 0) {
          for (const iterator of flatArray) {
            organizations = organizations?.filter(
              (organization) => organization?.value !== iterator?.value
            );
          }
          setFilterOrganizations(organizations);
        }

        const child_ids = flatArray?.map((org) => org?.value);

        if (flatArray.length > 0) {
          filterSelectedOrg = selectedOrganizations?.filter(
            (organization) => !child_ids?.includes(organization.value)
          );
        }
        setSelectedOrganizations(filterSelectedOrg);
      }
      setCanSeeChildOrg(event?.target?.checked);
    } else {
      setChildOrganizations([]);
      setFilterOrganizations(organizations);
      setCanSeeChildOrg(event?.target?.checked);
      setSelectedChildOrganizations([]);
    }
  };

  const handleOrganizationChange = (event) => {
    let child_org = [];
    let flatArray = [];
    let selected_child_org = [];

    let difference = selectedOrganizations?.filter((x) => !event?.includes(x)); // calculates diff

    if (canSeeChildOrg) {
      if (difference) {
        if (difference?.length > 0 && selectedChildOrganizations?.length > 0) {
          for (const iterator of difference) {
            selected_child_org = selectedChildOrganizations?.filter((so) => {
              return so.parent !== iterator?.value;
            });
          }
          setSelectedChildOrganizations(selected_child_org);
        }
      }

      if (event !== null) {
        for (const iterator of event) {
          let parent_id = iterator?.value;

          if (iterator.associated_orgs) {
            child_org?.push(
              iterator?.associated_orgs?.map((ao) => {
                return {
                  label: ao?.client_organization?.name,
                  value: ao?.client_organization?.id,
                  parent: parent_id,
                };
              })
            );

            flatArray = [].concat(...child_org);

            flatArray = flatArray?.filter((item, index, arr) => {
              return (
                arr.findIndex((item2) => {
                  return item2?.value === item?.value;
                }) === index
              );
            });

            setChildOrganizations(flatArray);
          }
        }

        if (flatArray.length > 0) {
          for (const iterator of flatArray) {
            organizations = organizations?.filter(
              (organization) => organization?.value !== iterator?.value
            );
          }
          setFilterOrganizations(organizations);
        }
      }
    }
    setSelectedOrganizations(event);

    if (event === null) {
      setSelectedOrganizations([]);
      setSelectedChildOrganizations([]);
      setChildOrganizations([]);
      setFilterOrganizations(organizations);
    }
  };

  const handleMaterialClassChange = (event) => {
    if(!event) {
      setSelectedMaterialClasses([])
      return;
    }
    setSelectedMaterialClasses(event)
  }

  const handleChangeQuestionCheckbox = (event) => {
    setSpillQuestions({
      ...spillQuestions,
      [event.target?.name]: {
        value: event?.target?.checked,
        name: event?.target?.name,
        key: 1,
        label: spillQuestions[event?.target?.name]?.label,
      },
    });
  };

  const handleSearchDateChange = (key) => (date) => {
    setSpillSelectData((val) => ({
      ...val,
      [key]: date,
    }));
  };

  const {
    open = null,
    work_in_progress = null,
    site_work_complete = null,
    extended_remediation = null,
    pending_excavation = null,
    documentation_in_review = null,
    pending_disposal = null,
    invoiced_but_not_paid = null,
    paid_but_contractor_not_yet_paid = null,
    documentation_sent_back_to_contractor_for_revision = null,
    closed = null,
    ready_to_invoice = null,
    final_review = null,
    ready_to_close = null,
    close = null,
  } = status || {};

  const organizationNames = clientOrganizationNames.data;
  const organizationChildren = clientChildren.data;
  const managerNames = admins.data;
  const organizationManagerNames = organizationAdmins.data;
  const agencyNames = agencies.data;

  let organizations = [];
  let managers = [];

  if (isCorporateUser(userRole) && userRole !== ROLES.CORPORATE_USER) {
    const organizationChildOptions = organizationChildren?.map(
      (organization) => {
        const value = {
          value: organization.id,
          label: organization.name,
          associated_orgs: organization.AssociatedOrganizations,
        };
        const allOptions = { ...value };

        return allOptions;
      }
    );

    organizations = organizationChildOptions;
  } else if (userRole === ROLES.CORPORATE_USER) {
    const organizationFilter = organizationChildren?.filter((organization) => {
      const org = organization.id === currentUser.data.org_id;

      return org;
    });

    const organizationOptions = organizationFilter?.map((organization) => {
      const value = {
        value: organization.id,
        label: organization.name,
        associated_orgs: organization.AssociatedOrganizations,
      };
      const allOptions = { ...value };

      return allOptions;
    });

    organizations = organizationOptions;
  } else {
    const organizationOptions = organizationNames?.map((organization) => {
      const value = {
        value: organization.id,
        label: organization.name,
        associated_orgs: organization.AssociatedOrganizations,
      };
      const allOptions = { ...value };

      return allOptions;
    });

    organizations = organizationOptions;
  }

  if (isCorporateUser(userRole)) {
    const managerOptions = organizationManagerNames?.map((manager) => {
      const value = {
        value: manager.id,
        label: manager.full_name,
        active: manager?.active,
      };
      const allOptions = { ...value };

      return allOptions;
    });

    managers = managerOptions;
  } else {
    const managerOptions = managerNames?.map((manager) => {
      const value = {
        value: manager.id,
        label: manager.full_name,
        active: manager.active,
      };
      const allOptions = { ...value };
      return allOptions;
    });

    managers = managerOptions;
  }

  const agencyOptions = agencyNames?.map((agency) => {
    const value = { value: agency.id, label: agency.name };
    const allOptions = { ...value };

    return allOptions;
  });

  const handleSpillChange = (key) => (event) => {
    setSpillSelectData((val) => ({ ...val, [key]: event.target.value }));
  };

  const setValue = (value) => {
    return value && value.trim && value !== '' ? value : null;
  }

  const onFinish = async (searchData) => {
    getStatuses();
    let statusData = [];
    let questionsData = {};
    let getAgencies = selectedAgencies ? [...selectedAgencies] : [];
    let getManagers = selectedManagers? [...selectedManagers] : [];
    let getUsersForEmail = selectedUsersForEmail ? [...selectedUsersForEmail] : [];
    let getPacketReview = selectedPacketReview ? [...selectedPacketReview] : [];
    let getSpillSelectData = {...spillSelectData};
    let getTextFieldsData = {...textFieldsData};
    const getLabelFromSelectedMaterialClasses = selectedMaterialClasses?.map(materialClass => materialClass?.label) ?? [];
    if (getLabelFromSelectedMaterialClasses?.length > 0) {
      let isSelectedNoneIndex = getLabelFromSelectedMaterialClasses?.findIndex(item => item === "Select Blank");
      if(isSelectedNoneIndex !== -1) {
        getLabelFromSelectedMaterialClasses[isSelectedNoneIndex] = null; //allow to search for empty material class
      }
    }

    for (let data in status) {
      if (status[data].value !== false) {
        statusData?.push(status[data].label);
      }
    }

    for (let question in spillQuestions) {
      if (spillQuestions[question]?.value !== false) {
        questionsData = {
          ...questionsData,
          [question]: pendingDisposalAllow ? 0 : spillQuestions[question]?.key,
        };
      }
    }

    delete searchData?.pendingdisposal;
    delete searchData?.subrogation;
    if (!defaultSearch && subrogationAllow) {
      searchData = {
        ...searchData,
        subrogation: 1
      }
    }

    searchData = {
      ...searchData,
      ...questionsData,
      ...getSpillSelectData,
      statusData,
      agencies: getAgencies,
      managers: getManagers,
      usersForEmail: getUsersForEmail,
      packetReviewUsers: getPacketReview,
      contractors: calculateContractors(selectedContractors, contractorOptions),
      rejectContractors: calculateContractors(
        selectedRejectContractors,
        contractorOptions
      ),
      organizations: selectedOrganizations,
      childOrganizations: canSeeChildOrg ? selectedChildOrganizations : '',
      id: currentUser?.data?.id,
      role: currentUser?.data?.role?.role,
      permission: currentUser?.data?.role?.permission?.view_related_spills,
      page: 0,
      materialClasses: getLabelFromSelectedMaterialClasses
    };
    if (pendingDisposalAllow) {
      getSpillSelectData = {
        type: '',
        state: '',
        country: '',
        opened_on: getSpillSelectData?.opened_on,
        opened_to: getSpillSelectData?.opened_to,
        close_on: getSpillSelectData?.close_on,
        close_to: getSpillSelectData?.close_to,
        quantity_type_released: null,
        damaged_container_type: null,
        damaged_type: null,
        location_type: null,
        disposal_handled_by: getSpillSelectData?.disposal_handled_by,
        container_disposition_type: getSpillSelectData?.container_disposition_type,
      };
      questionsData = {
        pendingdisposal: 1
      }
      searchData = {
        ...searchData,
        ...questionsData,
        ...getSpillSelectData,
        statusData: [],
        agencies: [],
        managers: [],
        usersForEmail: [],
        packetReviewUsers: [],
        pendingdisposal: 1
      }
      getTextFieldsData = {};
      statusData = [];
      getAgencies = [];
      getManagers = [];
      getUsersForEmail = [];
      getPacketReview = [];
    }

    Array.isArray(searchData?.contractors) &&
      searchData?.contractors?.forEach((contractor) => {
        delete contractor?.contractor_attachments_expiries
      })

    const organizations = selectedOrganizations?.map((organization) => {
      return organization.label;
    });
    const agencies = getAgencies?.map((agency) => {
      return agency.label;
    });
    const managers = getManagers?.map((manager) => {
      return manager.label;
    });
    const contractors = selectedContractors?.map((contractor) => {
      return contractor.label;
    });
    const rejectContractors = selectedRejectContractors?.map((contractor) => {
      return contractor.label;
    });

    const childOrganizations = selectedChildOrganizations?.map(
      (organization) => {
        return organization.label;
      }
    );

    const dataForFilter = [
      searchData.job_no !== '' && searchData.job_no && searchData.job_no?.trim() ? `Job Number: ${searchData.job_no}, ` : null,
      searchData.contact !== '' && searchData.contact && searchData.contact?.trim() ? `Contact: ${searchData.contact}, ` : null,
      searchData.claim_no !== '' && searchData.claim_no && searchData.claim_no?.trim() ? `Claim Number: ${searchData.claim_no}, `: null,
      searchData.conditions !== '' && searchData.conditions && searchData.conditions?.trim() ? `Conditions: ${searchData.conditions}, ` : null,
      searchData.material !== '' && searchData.material && searchData.material?.trim() ? `Material: ${searchData.material}, ` : null,
      searchData.responsible !== '' && searchData.responsible && searchData.responsible?.trim() ? `Responsible: ${searchData.responsible}, `
        : null,
      getSpillSelectData.type && getSpillSelectData.type?.trim() && getSpillSelectData.type !== '' ? `Type: ${getSpillSelectData.type}, ` : null,
      getSpillSelectData.state && getSpillSelectData.state?.trim() && getSpillSelectData.state !== '' ? `State: ${getSpillSelectData.state}, ` : null,
      getSpillSelectData.quantity_type_released &&
      `Quantity Type Released: ${getSpillSelectData.quantity_type_released}, `,
      getSpillSelectData.damaged_container_type &&
      `Damaged Container Type: ${getSpillSelectData.damaged_container_type}, `,
      getSpillSelectData.damaged_type &&
      `Damaged Type: ${getSpillSelectData.damaged_type}, `,
      getSpillSelectData.location_type &&
      `Location Type: ${getSpillSelectData.location_type}, `,
      getSpillSelectData.disposal_handled_by &&
      `Disposal Will Be Handled By: ${getSpillSelectData.disposal_handled_by}, `, 
      getSpillSelectData.container_disposition_type &&
      `Container Disposition: ${getSpillSelectData.container_disposition_type}, `,
      getSpillSelectData.opened_on
        ? `Opened On: ${getSpillSelectData.opened_on}, `
        : null,
      getSpillSelectData.opened_to
        ? `Opened To: ${getSpillSelectData.opened_to}, `
        : null,
      getSpillSelectData.close_on
        ? `Close On: ${getSpillSelectData.close_on}, `
        : null,
      getSpillSelectData.close_to
        ? `Close To: ${getSpillSelectData.close_to}, `
        : null,
      organizations?.length > 0 ? `Organizations: [${organizations}], ` : null,
      getLabelFromSelectedMaterialClasses?.length > 0 ? `Material Class: [${getLabelFromSelectedMaterialClasses}], ` : null,
      canSeeChildOrg && childOrganizations?.length > 0
        ? `Child Organizations: [${childOrganizations}], `
        : null,

      agencies?.length > 0 ? `Agencies: [${agencies}], ` : null,
      contractors?.length > 0 ? `Contractors: [${contractors}], ` : null,
      rejectContractors?.length > 0
        ? `Rejected Contractors: [${rejectContractors}], `
        : null,
      managers?.length > 0 ? `Managers: [${managers}], ` : null,
      statusData?.length > 0 ? `Status: [${statusData}], ` : null,
      Object.keys(questionsData).length
        ? `
        Questions: ${JSON.stringify(questionsData)}, `
        : null,
        getUsersForEmail?.length > 0
        ? `UsersForEmail: [${getUsersForEmail?.map((x) =>
          JSON.stringify(x?.label)
        )}] `
        : null,
        getPacketReview?.length > 0
        ? `packetReviewUsers: [${getPacketReview?.map((x) =>
          JSON.stringify(x?.label)
        )}] `
        : null,
      getSpillSelectData?.country ? `Country: ${getSpillSelectData?.country}` : null,
      getTextFieldsData?.city ? `City: ${getTextFieldsData?.city}` : null,
      getTextFieldsData?.address ? `Address: ${getTextFieldsData?.address}` : null,
      getTextFieldsData?.tractor ? `Tractor: ${getTextFieldsData?.tractor}` : null,
      getTextFieldsData?.trailer ? `Trailer: ${getTextFieldsData?.trailer}` : null,
      getTextFieldsData?.pro ? `Pro: ${getTextFieldsData?.pro}` : null,
      getTextFieldsData?.shipperName ? ` Shipper Name: ${getTextFieldsData?.shipperName}` : null,
      getTextFieldsData?.shipperAddress ? ` Shipper Address: ${getTextFieldsData?.shipperAddress}` : null,
      getTextFieldsData?.amount_released
        ? `Amount_Released: ${getTextFieldsData?.amount_released}`
        : null,
      getTextFieldsData?.pesInvoiceNo
        ? `PES Invoice Number: ${getTextFieldsData?.pesInvoiceNo}`
        : null,
      getTextFieldsData?.contractorInvoiceNo
        ? `Contractor Invoice Number: ${getTextFieldsData?.contractorInvoiceNo}`
        : null,
    ];

    const appliedFilters = {
      jobNumber: setValue(searchData.job_no),
      contact: setValue(searchData.contact),
      claimNumber: setValue(searchData.claim_no),
      conditions: setValue(searchData.conditions),
      material: setValue(searchData.material),
      responsible: setValue(searchData.responsible),
      type: setValue(searchData.type),
      state: setValue(searchData.state),
      quantityTypeReleased:
        getSpillSelectData.quantity_type_released &&
        getSpillSelectData.quantity_type_released,
      damagedContainerType:
        getSpillSelectData.damaged_container_type &&
        getSpillSelectData.damaged_container_type,
      damageType: getSpillSelectData.damaged_type && getSpillSelectData.damaged_type,
      country: getSpillSelectData.country && getSpillSelectData.country,
      locationType: getSpillSelectData.location_type && getSpillSelectData.location_type,
      disposalType: getSpillSelectData.disposal_handled_by && getSpillSelectData.disposal_handled_by , 
      containerDispostionType: getSpillSelectData.container_disposition_type && getSpillSelectData.container_disposition_type,
      openedOn: getSpillSelectData.opened_on ? getSpillSelectData.opened_on : null,
      openedTo: getSpillSelectData.opened_to ? getSpillSelectData.opened_to : null,
      closedOn: getSpillSelectData.close_on ? getSpillSelectData.close_on : null,
      closedTo: getSpillSelectData.close_to ? getSpillSelectData.close_to : null,
      organizations: selectedOrganizations?.length > 0 ? selectedOrganizations : null,
      materialClasses: selectedMaterialClasses?.length > 0 ? selectedMaterialClasses : null,
      childOrganizations: canSeeChildOrg && selectedChildOrganizations?.length > 0 ? selectedChildOrganizations : null,
      canSeeChildOrg: canSeeChildOrg,
      agencies: getAgencies?.length > 0 ? getAgencies : null,
      contractors: selectedContractors?.length > 0 ? selectedContractors : null,
      rejectContractors: selectedRejectContractors?.length > 0 ? selectedRejectContractors : null,
      managers: getManagers?.length > 0 ? getManagers : null,
      usersForEmail: getUsersForEmail?.length > 0 ? getUsersForEmail : null,
      packetReviewUsers: getPacketReview?.length > 0 ? getPacketReview : null,
      status: status,
      questions: spillQuestions || null,
      city: getTextFieldsData?.city || null,
      claim_no: getTextFieldsData?.claim_no || null,
      job_no: getTextFieldsData?.job_no || null,
      address: getTextFieldsData?.address || null,
      amount_released: getTextFieldsData?.amount_released || null,
      tractor: getTextFieldsData?.tractor || null,
      trailer: getTextFieldsData?.trailer || null,
      pro: getTextFieldsData?.pro || null,
      shipperName : getTextFieldsData?.shipperName || null,
      shipperAddress : getTextFieldsData?.shipperAddress || null,
      activeUsers: isActiveUsers,
      activeManagers: isActiveManagers,
      inActiveManagers: isInactiveManagers,
      isAllAgencies: isAllAgenciesSelected,
    };

    setSpillFilters(appliedFilters);

    setSpillDataForFilter(dataForFilter);

    setFinalSpillsSearchData({
      ...searchData,
      opened_on: forceToCST(searchData.opened_on),
      close_on: forceToCST(searchData.close_on),
      opened_to: forceToCST(searchData.opened_to),
      close_to: forceToCST(searchData.close_to),
    });
    searchSpills({
      ...searchData,
      opened_on: forceToCST(searchData.opened_on),
      close_on: forceToCST(searchData.close_on),
      opened_to: forceToCST(searchData.opened_to),
      close_to: forceToCST(searchData.close_to),
      job_no: searchData?.job_no?.trim(),
      city: searchData?.city?.trim(),
      claim_no: searchData?.claim_no?.trim(),
      material: searchData?.material?.trim(),
      conditions: searchData?.conditions?.trim(),
      contact: searchData?.contact?.trim(),
      responsible: searchData?.responsible?.trim(),
      address: searchData?.address?.trim(),
      trailer: searchData?.trailer?.trim(),
      tractor: searchData?.tractor?.trim(),
      pro: searchData?.pro?.trim(),
      shipperName: searchData?.shipperName?.trim(),
      shipperAddress: searchData?.shipperAddress?.trim(),
      amount_released: searchData?.amount_released?.trim(),
      pesInvoiceNo: searchData?.pesInvoiceNo?.trim(),
      contractorInvoiceNo: searchData?.contractorInvoiceNo?.trim(),
      userType: currentUser?.data?.test_user
        ? USER_TYPE.TEST
        : USER_TYPE.GENERAL,
    });

    setSelectedSpills([]);

    if (defaultSearch) {
      history.push(
        `/dashboard/search-results?token=${false}&report=${false}&subrogation=${false}&pendingdisposal=${false}`

      );
    } else {
      history.push(
        `/dashboard/search-results?token=${batchUpdateAllow}&report=${reportsAllow}&subrogation=${subrogationAllow}&pendingdisposal=${pendingDisposalAllow}&shipper=${isShipperReport}`
      );
    }
  };

  const handleClearSearch = () => {
    setSelectedUsersForEmail([]);
    setSelectedPacketReview([]);
    setSelectedOrganizations([]);
    setSelectedMaterialClasses([]);
    setSelectedManagers([]);
    setSelectedContractors([]);
    setSelectedRejectContractors([]);
    setSelectedAgencies([]);
    setSelectedChildOrganizations([]);
    setCanSeeChildOrg(false);
    setSpillSelectData({
      type: '',
      state: '',
      country: '',
      opened_on: null,
      opened_to: null,
      close_on: null,
      close_to: null,
      quantity_type_released: null,
      damaged_container_type: null,
      damaged_type: null,
      location_type: null,
      disposal_handled_by:null,
      container_disposition_type:null,
    });
    setTextFieldsData({
      city: '',
      claim_no: '',
      conditions: '',
      contact: '',
      job_no: '',
      material: '',
      responsible: '',
      address: '',
      amount_released: null,
      tractor: null,
      trailer: null,
      pro: null,
      shipperName: null,
      shipperAddress: null
    });

    let statusData = {};
    let questionData = {};

    if (status) {
      for (const [key, value] of Object.entries(status)) {
        statusData = {
          ...statusData,
          [key]: { ...value, value: false },
        };
      }
    }
    for (const [key, value] of Object.entries(spillQuestions ?? {})) {
      questionData = {
        ...questionData,
        [key]: {
          ...value,
          value: !defaultSearch && key === 'subrogation' && !isShipperReport ? true : !defaultSearch && key === 'pendingdisposal' ? true : false,
        },
      };
    }

    if (!subrogationAllow && !isShipperReport) {
      delete questionData.subrogation;
    } else if(!pendingDisposalAllow){
      delete questionData.pendingdisposal;
    }

    setStatus({ ...statusData });
    setSpillQuestions({ ...questionData });
  };

  const spillStatusFilteringOpen = (event) => {
    let statusData = {};

    for (const [key, value] of Object?.entries(status)) {
      if (value?.label?.search('Open: ') > -1) {
        statusData = {
          ...statusData,
          [key]: { ...value, value: event.target.checked },
        };
      } else {
        statusData = {
          ...statusData,
          [key]: { ...value },
        };
      }
    }
    setStatus({ ...statusData });
  };

  const spillStatusFilteringClose = (event) => {
    let statusData = {};

    for (const [key, value] of Object?.entries(status)) {
      if (value?.label?.search('Closed: ') > -1) {
        statusData = {
          ...statusData,
          [key]: { ...value, value: event?.target?.checked },
        };
      } else {
        statusData = {
          ...statusData,
          [key]: { ...value },
        };
      }
    }

    setStatus({ ...statusData });
  };

  const handleFieldChange = (key) => (event) => {
    setTextFieldsData((val) => ({ ...val, [key]: event?.target?.value }));
  };

  const maintainFiltersHandler = () => {
    const initialFiltersData = spillFiltersState?.data;

    if (Object.keys(initialFiltersData).length !== 0) {
      setTextFieldsData({
        pro: initialFiltersData?.pro,
        city: initialFiltersData?.city,
        address: initialFiltersData?.address,
        tractor: initialFiltersData?.tractor,
        trailer: initialFiltersData?.trailer,
        contact: initialFiltersData?.contact,
        job_no: initialFiltersData?.jobNumber,
        material: initialFiltersData?.material,
        claim_no: initialFiltersData?.claimNumber,
        conditions: initialFiltersData?.conditions,
        responsible: initialFiltersData?.responsible,
        amount_released: initialFiltersData?.amount_released,
        shipperName: initialFiltersData?.shipperName,
        shipperAddress: initialFiltersData?.shipperAddress
      });

      setSpillSelectData({
        type: initialFiltersData?.type,
        state: initialFiltersData?.state,
        country: initialFiltersData?.country,
        close_on: initialFiltersData?.closedOn,
        close_to: initialFiltersData?.closedTo,
        opened_on: initialFiltersData?.openedOn,
        opened_to: initialFiltersData?.openedTo,
        damaged_type: initialFiltersData?.damageType,
        location_type: initialFiltersData?.locationType,
        disposal_handled_by: initialFiltersData?.disposalType,
        container_disposition_type:initialFiltersData?.containerDispostionType,
        quantity_type_released: initialFiltersData?.quantityTypeReleased,
        damaged_container_type: initialFiltersData?.damagedContainerType,
      });
      setStatus(initialFiltersData?.status);
      initialFiltersData?.questions && setSpillQuestions(initialFiltersData?.questions);
      setSelectedAgencies(initialFiltersData?.agencies);
      setSelectedManagers(initialFiltersData?.managers);
      setSelectedContractors(initialFiltersData?.contractors);
      setSelectedRejectContractors(initialFiltersData?.rejectContractors);
      setSelectedOrganizations(initialFiltersData?.organizations);
      setSelectedMaterialClasses(initialFiltersData?.materialClasses ?? []);
      setSelectedChildOrganizations(initialFiltersData?.childOrganizations);
      setCanSeeChildOrg(initialFiltersData?.canSeeChildOrg);
      setSelectedUsersForEmail(initialFiltersData?.usersForEmail);
      setSelectedPacketReview(initialFiltersData?.packetReviewUsers);
      setActiveUsers(initialFiltersData?.activeUsers);
      setActiveManagers(initialFiltersData?.activeManagers);
      setIsAllAgenciesSelected(initialFiltersData?.isAllAgencies);
    }
  };

  const handleOnKeyDown = (e, searchFilter, setSearchFilter) => {
    const { code } = e;
    if (code?.toLowerCase() === 'backspace' && searchFilter?.length === 1) {
      let newSearch = searchFilter;
      newSearch = newSearch?.substring(0, newSearch.length - 1);

      setSearchFilter(newSearch);
    }
  };

  const handleChangeActiveUsers = (e) => {
    const isChecked = e?.target?.checked;
    setInactiveUsersCheckboxEnabled(isChecked);
    setActiveUsers(isChecked);
  };

  const handleChangeInactiveUsers = (e) => {
    const isChecked = e?.target?.checked;
    setActiveUsersCheckboxEnabled(isChecked);
    setInactiveUsers(isChecked);
  };

  const handleChangeActiveManagers = (e) => {
    const isChecked = e?.target?.checked;
    setInactiveManagersCheckboxEnabled(isChecked);
    setActiveManagers(isChecked);
  };

  const handleChangeInactiveManagers = (e) => {
    const isChecked = e?.target?.checked;
    setActiveManagersCheckboxEnabled(isChecked);
    setInactiveManagers(isChecked);
  };

  const handleChangeAllAgencies = (e) => {
    setIsAllAgenciesSelected(e?.target?.checked);
    if (e?.target?.checked) {
      setSelectedAgencies(agencyOptions);
    }
    if (e?.target?.checked === false) {
      setSelectedAgencies([]);
    }
  };

  const handleCancel = () => {
    window.scroll({
      top: 0,
      behavior: "smooth"
    })
    switchReportsHandler && switchReportsHandler(null) || goToSpills()
  }

  return (
    <React.Fragment>
      <div style={{ paddingTop: 10 }} />

      <Container maxWidth='lg'>
        <form
          onSubmit={handleSubmit((data) => onFinish(data))}
          style={{ width: '100%' }}
          onKeyDown={(event) =>
            (event.key = 'Enter' && formRef?.current?.click())
          }
          tabIndex={-1}
        >
          <Grid container spacing={3}>
            <Grid item xs={8} style={{ textAlign: 'left' }}>
              <Typography variant='h5' gutterBottom>
                Search Spills
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              <Button
                variant='contained'
                color='primary'
                type='reset'
                onClick={() => handleClearSearch()}
              >
                Clear filters
              </Button>

            </Grid>
          </Grid>
          {loading ? (
            <CustomProgressLoader show={true} />
          ) : (
            <Grid container spacing={3}>
              {!shouldHideFields &&
                <>
                  <Grid
                    item
                    xs={2}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    Job Number
                  </Grid>

                  <Grid item xs={10}>
                    <TextField
                      id='job_no'
                      name='job_no'
                      inputProps={{
                        value: textFieldsData.job_no,
                      }}
                      onChange={handleFieldChange('job_no')}
                      fullWidth
                      inputRef={register}
                      autoComplete='job_no'
                    />
                  </Grid>
                </>
              }
              <Grid item xs={2}>
                Organizations
              </Grid>
              <Grid item xs={7} className={classes.alignLeft}>
                <ReactSelect
                  inputValue={organizationFilter}
                  value={selectedOrganizations}
                  required
                  isMulti
                  onChange={handleOrganizationChange}
                  options={orderAlphabaticallyByKey(
                    filterOrganizations?.length > 0
                      ? filterOrganizations
                      : organizations
                  )}
                  onKeyDown={(e) => {
                    handleOnKeyDown(
                      e,
                      organizationFilter,
                      setOrganizationFilter
                    );
                  }}
                  onInputChange={(e) => {
                      setOrganizationFilter(e);
                  }}
                  isDisabled={isContractorUser(currentUser?.data?.role?.role)}
                  closeMenuOnSelect={false}
                />
              </Grid>
              <Grid item xs={3} className={classes.leftAlign}>
                {' '}
                <Switch
                  labelId='default-user-label'
                  id='default-switch-parent-user'
                  checked={canSeeChildOrg}
                  onChange={handleChildToggle}
                  name='canSeeChildOrg'
                  color='primary'
                  disabled={isContractorUser(currentUser?.data?.role?.role)}
                />
                <Typography variant='subtitle' gutterBottom>
                  Child Organization
                </Typography>
              </Grid>


              {canSeeChildOrg && (
                <>
                  <Grid item xs={2}>
                    Child Organizations
                  </Grid>
                  <Grid item xs={10} className={classes.alignLeft}>
                    <ReactSelect
                      value={selectedChildOrganizations}
                      required
                      isMulti
                      onChange={handleChildSelectedOrganizations}
                      options={orderAlphabaticallyByKey(childOrganizations)}
                      isDisabled={isContractorUser(
                        currentUser?.data?.role?.role
                      )}
                      closeMenuOnSelect={false}
                      onKeyDown={(e) => {
                        handleOnKeyDown(
                          e,
                          childOrganizationFilter,
                          setChildOrganizationFilter
                        );
                      }}
                      onInputChange={(e) => {
                        if (e !== '') {
                          setChildOrganizationFilter(e);
                        }
                      }}
                      inputValue={childOrganizationFilter}
                    />
                  </Grid>
                </>
              )}
              {!shouldHideFields &&
                <>
                  <Grid item xs={2}>
                    Managers
                  </Grid>
                  <Grid item xs={10} className={classes.alignLeft}>
                    <ReactSelect
                      value={selectedManagers}
                      required
                      isMulti
                      onChange={setSelectedManagers}
                      options={orderAlphabaticallyByKey(managers)?.filter((item) => {
                        return isActiveManagers
                          ? item.active === true
                          : isInactiveManagers
                            ? item.active === false
                            : true;
                      })}
                      isDisabled={isContractorUser(currentUser?.data?.role?.role)}
                      closeMenuOnSelect={false}
                      onKeyDown={(e) => {
                        handleOnKeyDown(e, managerFilter, setManagerFilter);
                      }}
                      onInputChange={(e) => {
                        if (e !== '') {
                          setManagerFilter(e);
                        }
                      }}
                      inputValue={managerFilter}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={
                            isContractorUser(currentUser?.data?.role?.role) ||
                            isActiveManagersCheckboxEnabled
                          }
                          checked={isActiveManagers}
                          onChange={handleChangeActiveManagers}
                          name='active_managers'
                          classes={{
                            root: classes.root,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label='Active Managers'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={
                            isContractorUser(currentUser?.data?.role?.role) ||
                            isInactiveManagersCheckboxEnabled
                          }
                          checked={isInactiveManagers}
                          onChange={handleChangeInactiveManagers}
                          name='inactive_managers'
                          classes={{
                            root: classes.root,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label='Inactive Managers'
                    />
                  </Grid>
                  <Grid item xs={2}>
                    Users for Email
                  </Grid>
                  <Grid item xs={10} className={classes.alignLeft}>
                    <ReactSelect
                      value={selectedUsersForEmail}
                      required
                      isMulti
                      onChange={setSelectedUsersForEmail}
                      options={orderAlphabaticallyByKey(
                        users?.data
                          ?.map((x) => ({
                            value: x?.user?.id,
                            label: x?.user?.full_name,
                            spill_ids: x?.spill_ids,
                            active: x?.user?.active,
                          }))
                          ?.filter((item) =>
                            isActiveUsers
                              ? item?.active === 1
                              : isInactiveUsers
                                ? item?.active === 0
                                : true
                          )
                      )}
                      isDisabled={isContractorUser(currentUser?.data?.role?.role)}
                      closeMenuOnSelect={false}
                      onKeyDown={(e) => {
                        handleOnKeyDown(e, usersFilter, setUsersFilter);
                      }}
                      onInputChange={(e) => {
                        if (e !== '') {
                          setUsersFilter(e);
                        }
                      }}
                      inputValue={usersFilter}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={
                            isContractorUser(currentUser?.data?.role?.role) ||
                            isActiveUsersCheckboxEnabled
                          }
                          checked={isActiveUsers}
                          onChange={handleChangeActiveUsers}
                          name='active_users'
                          classes={{
                            root: classes.root,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label='Active Users'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={
                            isContractorUser(currentUser?.data?.role?.role) ||
                            isInactiveUsersCheckboxEnabled
                          }
                          checked={isInactiveUsers}
                          onChange={handleChangeInactiveUsers}
                          name='inactive_users'
                          classes={{
                            root: classes.root,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label='Inactive Users'
                    />
                  </Grid>
                  <Grid item xs={2}>
                    Packet Review Users
                  </Grid>
                  <Grid item xs={10} className={classes.alignLeft}>
                    <ReactSelect
                      value={selectedPacketReview}
                      required
                      isMulti
                      onChange={setSelectedPacketReview}
                      options={orderAlphabaticallyByKey(
                        fetchedPacketReviewersData?.data?.map((x) => ({
                          value: x?.id,
                          label: x?.full_name,
                          is_packet_reviewer:
                            x?.is_packet_reviewer === 1 ? true : false,
                        }))
                      ).filter((item) => item?.is_packet_reviewer === true)}
                      isDisabled={isContractorUser(currentUser?.data?.role?.role)}
                      closeMenuOnSelect={false}
                      onKeyDown={(e) => {
                        handleOnKeyDown(
                          e,
                          packetReviewFilter,
                          setPacketReviewFilter
                        );
                      }}
                      onInputChange={(e) => {
                        if (e !== '') {
                          setPacketReviewFilter(e);
                        }
                      }}
                      inputValue={packetReviewFilter}
                    />
                  </Grid>
                </>
              }
              <Grid item xs={12}>
                <Typography variant='subtitle2'>
                  Open (MM-DD-YYYY hh:mm am/pm)
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDateTimePicker
                    value={spillSelectData?.opened_on}
                    onChange={handleSearchDateChange('opened_on')}
                    label={'Opened on'}
                    showTodayButton
                    ampmInClock={true}
                    clearable={true}
                    format='MM-DD-YYYY hh:mm a'
                    initialFocusedDate={moment(new Date()).startOf('day').format('MM-DD-YYYY hh:mm a')}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDateTimePicker
                    value={spillSelectData?.opened_to}
                    onChange={handleSearchDateChange('opened_to')}
                    label={'Opened to'}
                    showTodayButton
                    ampmInClock={true}
                    clearable={true}
                    format='MM-DD-YYYY hh:mm a'
                    initialFocusedDate={moment(new Date()).startOf('day').format('MM-DD-YYYY hh:mm a')}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='subtitle2'>
                  Close (MM-DD-YYYY hh:mm am/pm)
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDateTimePicker
                    value={spillSelectData?.close_on}
                    onChange={handleSearchDateChange('close_on')}
                    label={'Closed on'}
                    showTodayButton
                    ampmInClock={true}
                    clearable={true}
                    format='MM-DD-YYYY hh:mm a'
                    initialFocusedDate={moment(new Date()).startOf('day').format('MM-DD-YYYY hh:mm a')}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDateTimePicker
                    value={spillSelectData?.close_to}
                    onChange={handleSearchDateChange('close_to')}
                    label={'Closed to'}
                    showTodayButton
                    ampmInClock={true}
                    clearable={true}
                    format='MM-DD-YYYY hh:mm a'
                    initialFocusedDate={moment(new Date()).startOf('day').format('MM-DD-YYYY hh:mm a')}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              {!shouldHideFields &&
                <>
                  <Grid item xs={2}>
                    <Typography variant='subtitle2'>Status</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={open?.value}
                            onChange={spillStatusFilteringOpen}
                            name='open'
                            classes={{
                              root: classes.root,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label='Open (any)'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={work_in_progress?.value}
                            onChange={handleChangeCheckbox}
                            name='work_in_progress'
                            classes={{
                              root: classes.root,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label='Work In Progress'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={site_work_complete?.value}
                            onChange={handleChangeCheckbox}
                            name='site_work_complete'
                            classes={{
                              root: classes.root,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label='Site Work Complete'
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={extended_remediation?.value}
                            onChange={handleChangeCheckbox}
                            name='extended_remediation'
                            classes={{
                              root: classes.root,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label='Extended Remediation'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={pending_excavation?.value}
                            onChange={handleChangeCheckbox}
                            name='pending_excavation'
                            classes={{
                              root: classes.root,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label='Pending Excavation'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={documentation_in_review?.value}
                            onChange={handleChangeCheckbox}
                            name='documentation_in_review'
                            classes={{
                              root: classes.root,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label='Documentation In Review'
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={pending_disposal?.value}
                            onChange={handleChangeCheckbox}
                            name='pending_disposal'
                            classes={{
                              root: classes.root,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label='Pending Disposal'
                      />
                      {isPESUser(currentUser?.data?.role?.role) && (
                        <>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={ready_to_invoice?.value}
                                onChange={handleChangeCheckbox}
                                name='ready_to_invoice'
                                classes={{
                                  root: classes.root,
                                  checked: classes.checked,
                                }}
                              />
                            }
                            label='Ready to Invoice'
                          />

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={final_review?.value}
                                onChange={handleChangeCheckbox}
                                name='final_review'
                                classes={{
                                  root: classes.root,
                                  checked: classes.checked,
                                }}
                              />
                            }
                            label='Final Review'
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={ready_to_close?.value}
                                onChange={handleChangeCheckbox}
                                name='ready_to_close'
                                classes={{
                                  root: classes.root,
                                  checked: classes.checked,
                                }}
                              />
                            }
                            label='Ready to Close'
                          />
                        </>
                      )}
                      <FormControlLabel
                        control={
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'flex-start',
                            }}
                          >
                            <Checkbox
                              checked={
                                documentation_sent_back_to_contractor_for_revision?.value
                              }
                              onChange={handleChangeCheckbox}
                              name='documentation_sent_back_to_contractor_for_revision'
                              classes={{
                                root: classes.root,
                                checked: classes.checked,
                              }}
                            />
                            <Typography
                              style={{ paddingTop: '8px', textAlign: 'left' }}
                            >
                              Documentation Sent Back to Contractor for Revision
                            </Typography>
                          </div>
                        }
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={5}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={close?.value}
                            onChange={spillStatusFilteringClose}
                            name='close'
                            classes={{
                              root: classes.root,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label='Closed (any)'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={invoiced_but_not_paid?.value}
                            onChange={handleChangeCheckbox}
                            name='invoiced_but_not_paid'
                            classes={{
                              root: classes.root,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label='Invoice Submitted to Client'
                      />
                      {!isCorporateUser(currentUser?.data?.role?.role) && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={paid_but_contractor_not_yet_paid?.value}
                              onChange={handleChangeCheckbox}
                              name='paid_but_contractor_not_yet_paid'
                              classes={{
                                root: classes.root,
                                checked: classes.checked,
                              }}
                            />
                          }
                          label='Payment Pending'
                        />
                      )}

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={closed?.value}
                            onChange={handleChangeCheckbox}
                            name='closed'
                            classes={{
                              root: classes.root,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label='Closed'
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'left' }}>
                    <InputLabel id='type_select_label'>Type</InputLabel>
                    <Select
                      labelId='type_select_label'
                      id='type_select'
                      name='type'
                      inputProps={{
                        value: spillSelectData?.type,
                      }}
                      onChange={handleSpillChange('type')}
                      style={{ width: '100%' }}
                    >
                      <MenuItem value=''>
                        <em>Select Type</em>
                      </MenuItem>
                      <MenuItem value={'Highway'}>Highway</MenuItem>
                      <MenuItem value={'Dock'}>Dock</MenuItem>
                      <MenuItem value={'File Review'}>File Review</MenuItem>
                      <MenuItem value={'BioHazard'}>BioHazard</MenuItem>
                      <MenuItem value={'Monthly Tracking File'}>
                        Monthly Tracking File
                      </MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id='city'
                      name='city'
                      label='City'
                      inputProps={{
                        value: textFieldsData.city,
                      }}
                      onChange={handleFieldChange('city')}
                      fullWidth
                      inputRef={register}
                      autoComplete='city'
                    />
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'left' }}>
                    <InputLabel id='state_select_label'>State</InputLabel>
                    <Select
                      labelId='state_select_label'
                      id='state_select'
                      name='state'
                      inputProps={{
                        value: spillSelectData?.state,
                      }}
                      onChange={handleSpillChange('state')}
                      style={{ width: '100%' }}
                    >
                      <MenuItem value=''>
                        <em>Select State</em>
                      </MenuItem>
                      {States &&
                        orderAlphabaticallyByKey(States, 'text')?.map((e, id) => {
                          return (
                            <MenuItem
                              value={
                                states.find((state) => state.name === e.text)
                                  ?.key || e.text
                              }
                            >
                              {e.text}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'left' }}>
                    <InputLabel id='country_select_label'>Country</InputLabel>
                    <Select
                      labelId='country_select_label'
                      id='country_select'
                      name='country'
                      inputProps={{
                        value: spillSelectData?.country,
                      }}
                      onChange={handleSpillChange('country')}
                      style={{ width: '100%' }}
                    >
                      <MenuItem value=''>
                        <em>Select Country</em>
                      </MenuItem>
                      <MenuItem value={COUNTRIES?.USA}>
                        {COUNTRIES?.USA}
                      </MenuItem>
                      <MenuItem value={COUNTRIES?.CANADA}>{COUNTRIES?.CANADA}</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'left' }}>
                    <InputLabel id='quantity_type_released_select_label'>
                      Quantity Type Released
                    </InputLabel>
                    <Select
                      labelId='quantity_type_released_select_label'
                      id='quantity_type_released'
                      name='quantity_type_released'
                      inputProps={{
                        value: spillSelectData?.quantity_type_released,
                      }}
                      onChange={handleSpillChange('quantity_type_released')}
                      style={{ width: '100%' }}
                    >
                      <MenuItem value=''>
                        <em>Select Quantity Type Released</em>
                      </MenuItem>
                      {quantityTypes &&
                        orderAlphabatically(quantityTypes)?.map((type, index) => {
                          return (
                            <MenuItem key={index} value={type}>
                              {type}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'left' }}>
                    <InputLabel id='damaged_container_type_select_label'>
                      Damaged Container Type
                    </InputLabel>
                    <Select
                      labelId='damaged_container_type_select_label'
                      id='damaged_container_type'
                      name='damaged_container_type'
                      inputProps={{
                        value: spillSelectData?.damaged_container_type,
                      }}
                      onChange={handleSpillChange('damaged_container_type')}
                      style={{ width: '100%' }}
                    >
                      <MenuItem value=''>
                        <em>Select Damaged Container Type</em>
                      </MenuItem>
                      {damagedContainerTypes &&
                        orderAlphabatically(damagedContainerTypes)?.map(
                          (type, index) => {
                            return (
                              <MenuItem key={index} value={type}>
                                {type}
                              </MenuItem>
                            );
                          }
                        )}
                    </Select>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'left' }}>
                    <InputLabel id='damaged_type_select_label'>
                      Damaged Type
                    </InputLabel>
                    <Select
                      labelId='damaged_type_select_label'
                      id='damaged_type'
                      name='damaged_type'
                      inputProps={{
                        value: spillSelectData?.damaged_type,
                      }}
                      onChange={handleSpillChange('damaged_type')}
                      style={{ width: '100%' }}
                    >
                      <MenuItem value=''>
                        <em>Select Damaged Type</em>
                      </MenuItem>
                      {damageTypes &&
                        orderAlphabatically(damageTypes)?.map((type, index) => {
                          return (
                            <MenuItem key={index} value={type}>
                              {type}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id='claim_no'
                      name='claim_no'
                      label='Claim number'
                      inputProps={{
                        value: textFieldsData.claim_no,
                      }}
                      onChange={handleFieldChange('claim_no')}
                      fullWidth
                      inputRef={register}
                      autoComplete='claim_no'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id='material'
                      name='material'
                      label='Material'
                      inputProps={{
                        value: textFieldsData.material,
                      }}
                      onChange={handleFieldChange('material')}
                      fullWidth
                      inputRef={register}
                      autoComplete='material'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id='conditions'
                      name='conditions'
                      inputProps={{
                        value: textFieldsData.conditions,
                      }}
                      onChange={handleFieldChange('conditions')}
                      label='Site conditions'
                      fullWidth
                      inputRef={register}
                      autoComplete='conditions'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id='contact'
                      name='contact'
                      label='Point of contact'
                      inputProps={{
                        value: textFieldsData.contact,
                      }}
                      onChange={handleFieldChange('contact')}
                      fullWidth
                      inputRef={register}
                      autoComplete='contact'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id='responsible'
                      name='responsible'
                      inputProps={{
                        value: textFieldsData.responsible,
                      }}
                      onChange={handleFieldChange('responsible')}
                      label='Responsible party'
                      fullWidth
                      inputRef={register}
                      autoComplete='responsible'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id='address'
                      name='address'
                      inputProps={{
                        value: textFieldsData.address,
                      }}
                      onChange={handleFieldChange('address')}
                      label='Address'
                      fullWidth
                      inputRef={register}
                      autoComplete='address'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id='tractor'
                      name='tractor'
                      value={
                        textFieldsData.tractor === null
                          ? ''
                          : textFieldsData.tractor
                      }
                      onChange={handleFieldChange('tractor')}
                      label='Tractor #'
                      fullWidth
                      inputRef={register}
                      autoComplete='tractor'
                    />
                  </Grid>{' '}
                  <Grid item xs={6}>
                    <TextField
                      id='trailer'
                      name='trailer'
                      value={
                        textFieldsData?.trailer === null
                          ? ''
                          : textFieldsData?.trailer
                      }
                      onChange={handleFieldChange('trailer')}
                      label='Trailer #'
                      fullWidth
                      inputRef={register}
                      autoComplete='trailer'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id='pro'
                      name='pro'
                      value={
                        textFieldsData?.pro === null ? '' : textFieldsData?.pro
                      }
                      onChange={handleFieldChange('pro')}
                      label='Pro #'
                      fullWidth
                      inputRef={register}
                      autoComplete='pro'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id='amount_released'
                      name='amount_released'
                      inputProps={{
                        min: 0,
                      }}
                      value={
                        textFieldsData.amount_released === null
                          ? ''
                          : textFieldsData?.amount_released
                      }
                      onChange={handleFieldChange('amount_released')}
                      label='Amount Released'
                      fullWidth
                      inputRef={register}
                      autoComplete='amount_released'
                      type='number'
                    />
                  </Grid>
                  {isPESUser(currentUser?.data?.role?.role) && (
                    <Grid item xs={6}>
                      <TextField
                        id='pesInvoiceNo'
                        name='pesInvoiceNo'
                        onChange={handleFieldChange('pesInvoiceNo')}
                        label='PES invoice no'
                        fullWidth
                        inputRef={register}
                        type='number'
                      />
                    </Grid>
                  )}
                  {(isPESUser(currentUser?.data?.role?.role) ||
                    isContractorUser(currentUser?.data?.role?.role)) && (
                      <Grid item xs={6}>
                        <TextField
                          id='contractorInvoiceNo'
                          name='contractorInvoiceNo'
                          onChange={handleFieldChange('contractorInvoiceNo')}
                          label='Contractor invoice no'
                          fullWidth
                          inputRef={register}
                          type='number'
                        />
                      </Grid>
                    )}
                  {isShipperReport ? (
                    <>
                     <Grid item xs={6}>
                     <TextField
                       id='pro'
                       label='Shipper name'
                       value={
                        textFieldsData?.shipperName === null ? '' : textFieldsData?.shipperName
                      }
                       name='shipperName'
                       onChange={handleFieldChange('shipperName')}
                       fullWidth
                       inputRef={register}
                     />
                   </Grid>
                   <Grid item xs={6}>
                     <TextField
                       id='pro'
                       label='Shipper address'
                       value={
                        textFieldsData?.shipperAddress === null ? '' : textFieldsData?.shipperAddress
                      }
                       name='shipperAddress'
                       onChange={handleFieldChange('shipperAddress')}
                       fullWidth
                       inputRef={register}
                     />
                   </Grid>
                   </>
                  ) : null}
                   
                  <Grid item xs={12} />
                  <Grid container xs={12} style={{padding:"15px 0"}}>
                    <Grid item xs={1}>
                      Material Class
                    </Grid>
                    <Grid item xs={11} className={classes.alignLeft}>
                      <ReactSelect
                        inputValue={materialClassFilter}
                        value={selectedMaterialClasses}
                        required
                        isMulti
                        onChange={handleMaterialClassChange}
                        options={[
                          { value: null, label: 'Select Blank' },
                          ...orderAlphabaticallyByKey(materialClassData)
                        ]}
                        onKeyDown={(e) => {
                          handleOnKeyDown(
                            e,
                            materialClassFilter,
                            setMaterialClassFilter
                          );
                        }}
                        onInputChange={(e) => {
                          setMaterialClassFilter(e);
                        }}
                        closeMenuOnSelect={false}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} />
                  <Grid xs={2} />
                  <Grid item xs={12} className={classes.leftAlign}>
                    <InputLabel
                      id='location_type_label'
                      className={classes.locationTypeLabel}
                    >
                      Location Type:
                    </InputLabel>
                    <RadioGroup
                      aria-label='location_type_radio_group'
                      name='location_type'
                      className={classes.radioGroupDisplay}
                      value={spillSelectData?.location_type}
                      inputRef={register}
                      onChange={handleSpillChange('location_type')}
                    >
                      <FormControlLabel
                        value='Client Facility'
                        control={<Radio color='primary' />}
                        label='Client Facility'
                      />
                      <FormControlLabel
                        value='Customers Location'
                        control={<Radio color='primary' />}
                        label='Customers Location'
                      />
                      <FormControlLabel
                        value='Roadway'
                        control={<Radio color='primary' />}
                        label='Roadway'
                      />
                    </RadioGroup>
                  </Grid>
                </>
              }

              <Grid item md={6} className={classes.leftAlign}>
                <InputLabel
                  id='disposal_handled_by_label'
                  className={classes.locationTypeLabel}
                >
                  Disposal Will Be Handled By:
                </InputLabel>
                <RadioGroup
                  aria-label='disposal_handled_by_radio_group'
                  name='disposal_handled_by'
                  className={classes.radioGroupDisplay}
                  value={spillSelectData?.disposal_handled_by}
                  inputRef={register}
                  onChange={handleSpillChange('disposal_handled_by')}
                >
                  <FormControlLabel
                    value='Contractor'
                    control={<Radio color='primary' />}
                    label='Contractor'
                  />
                  <FormControlLabel
                    value='Client'
                    control={<Radio color='primary' />}
                    label='Client'
                  />
                </RadioGroup>
              </Grid>
              <Grid item md={6} className={classes.leftAlign}>
                <InputLabel
                  id='container_disposition_type_label'
                  className={classes.locationTypeLabel}
                >
                  Container Disposition:
                </InputLabel>
                <RadioGroup
                  aria-label='container_disposition_type_radio_group'
                  name='container_disposition_type'
                  className={classes.radioGroupDisplay}
                  value={spillSelectData?.container_disposition_type}
                  inputRef={register}
                  onChange={handleSpillChange('container_disposition_type')}
                >
                  <FormControlLabel
                    value='Taken by Contractor'
                    control={<Radio color='primary' />}
                    label='Taken by Contractor'
                  />
                  <FormControlLabel
                    value='Left at Facility'
                    control={<Radio color='primary' />}
                    label='Left at Facility'
                  />
                </RadioGroup>
              </Grid>
              {!shouldHideFields &&
                <>
                  {Object.values(spillQuestions ?? {})?.map((key, index) => {
                    return (
                      <Grid item xs={4}>
                        <div style={{ textAlign: 'left' }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={key?.value ?? false}
                                onChange={handleChangeQuestionCheckbox}
                                name={key?.name}
                                classes={{
                                  root: classes.root,
                                  checked: classes.checked,
                                }}
                              />
                            }
                            label={key?.label}
                          />
                        </div>
                      </Grid>
                    );
                  })}
                  <Grid item xs={12} />
                  <Grid item xs={3}>
                    <Typography variant='subtitle2'>Agencies</Typography>
                  </Grid>
                  <Grid item xs={9} className={classes.alignLeft}>
                    <ReactSelect
                      inputValue={agenciesFilter}
                      value={selectedAgencies}
                      required
                      isMulti
                      onChange={(val) => {
                        setSelectedAgencies(val);
                      }}
                      closeMenuOnSelect={false}
                      options={orderAlphabaticallyByKey(agencyOptions)}
                      isDisabled={isContractorUser(currentUser?.data?.role?.role)}
                      onKeyDown={(e) => {
                        handleOnKeyDown(e, agenciesFilter, setAgenciesFilter);
                      }}
                      onInputChange={(e) => {
                        if (e !== '') {
                          setAgenciesFilter(e);
                        }
                      }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={isContractorUser(currentUser?.data?.role?.role)}
                          checked={isAllAgenciesSelected}
                          onChange={handleChangeAllAgencies}
                          name='all_agencies'
                          classes={{
                            root: classes.root,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label='All Agencies'
                    />
                  </Grid>
                </>
              }
              {!isCorporateUser(currentUser?.data?.role?.role) && (
                <>
                  <Grid item xs={3}>
                    <Typography variant='subtitle2'>
                      Contractors
                      <br />
                      (Response Accepted)
                    </Typography>
                  </Grid>
                  <Grid item xs={9} className={classes.alignLeft}>
                    <ReactSelect
                      inputValue={contractorsAccepFilter}
                      value={selectedContractors}
                      required
                      isMulti
                      onKeyDown={(e) => {
                        handleOnKeyDown(
                          e,
                          contractorsAccepFilter,
                          setContractorsAccepFilter
                        );
                      }}
                      onInputChange={(e) => {
                        if (e !== '') {
                          setContractorsAccepFilter(e);
                        }
                      }}
                      onChange={setSelectedContractors}
                      filterOption={filterOption}
                      options={contractorOptions}
                      styles={colourStyles()}
                      closeMenuOnSelect={false}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant='subtitle2'>
                      Contractors
                      <br /> (Response Rejected)
                    </Typography>
                  </Grid>
                  <Grid item xs={9} className={classes.alignLeft}>
                    <ReactSelect
                      inputValue={contractorsRejFilter}
                      value={selectedRejectContractors}
                      required
                      isMulti
                      onChange={setSelectedRejectContractors}
                      filterOption={filterOption}
                      options={contractorOptions}
                      styles={colourStyles()}
                      closeMenuOnSelect={false}
                      onInputChange={(e) => {
                        if (e !== '') {
                          setContractorsRejFilter(e);
                        }
                      }}
                      onKeyDown={(e) => {
                        handleOnKeyDown(
                          e,
                          contractorsRejFilter,
                          setContractorsRejFilter
                        );
                      }}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} style={{ textAlign: 'right' }}>
                <Button
                  className={classes.button}
                  variant='contained'
                  color='primary'
                  type='submit'
                >
                  Search
                </Button>
                <Button
                  className={classes.button}
                  variant='contained'
                  color='primary'
                  onClick={() => handleCancel()}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          )}
        </form>
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = ({
  user: {
    loading: userLoading,
    admins,
    users,
    organizationAdmins,
    currentUser,
    packetReviewers,
  },
  spill: {
    status: { statuses },
    success,
    spillFiltersState,
    loading: spillsLoading,
    materialClass,
  },
  client,
  agency,
  contractor,
  service,
}) => ({
  admins: admins,
  users: users,
  organizationAdmins: organizationAdmins,
  currentUser: currentUser,
  success: success,
  clientChildren: client.clientChildren,
  clientOrganizationNames: client.clientOrganizationNames,
  agencies: agency.agencies,
  spillFiltersState,
  contractorsWithAddress: contractor.contractorsWithAddress,
  loading:
    userLoading ||
    spillsLoading ||
    client.loading ||
    agency.loading ||
    contractor.loading,
  service,
  spillsLoading: spillsLoading,
  statuses: statuses,
  packetReviewers,
  materialClass,
});

const mapDispatchToProps = (dispatch) => ({
  searchSpills: bindActionCreators(spillActions.searchSpills, dispatch),
  getOrganizationNames: bindActionCreators(
    clientActions.getOrganizationNames,
    dispatch
  ),
  getOrganizationChildren: bindActionCreators(
    clientActions.getChildren,
    dispatch
  ),
  getAdmins: bindActionCreators(userActions.getAdmins, dispatch),
  getOrganizationAdmins: bindActionCreators(
    userActions.getOrganizationAdmins,
    dispatch
  ),
  getAgencies: bindActionCreators(agencyActions.getAgencies, dispatch),
  getContractorsWithAddress: bindActionCreators(
    contractorAction.getContractorsWithAddress,
    dispatch
  ),
  clearSpillsSearch: bindActionCreators(
    spillActions.clearSpillsSearch,
    dispatch
  ),
  getServices: bindActionCreators(servicesActions.getServices, dispatch),
  getUsersForEmail: bindActionCreators(userActions.getUsersForEmail, dispatch),
  getStatuses: bindActionCreators(spillActions.getStatuses, dispatch),
  updateStatus: bindActionCreators(spillActions.updateStatus, dispatch),
  setSpillFilters: bindActionCreators(spillActions.setSpillFilters, dispatch),
  setSpillDataForFilter: bindActionCreators(
    spillActions.setSpillDataForFilter,
    dispatch
  ),
  setFinalSpillsSearchData: bindActionCreators(
    spillActions.setFinalSpillsSearchData,
    dispatch
  ),
  setSelectedSpills: bindActionCreators(
    spillActions.setSelectedSpills,
    dispatch
  ),
  fetchPacketReviewers: bindActionCreators(
    userActions.fetchPacketReviewers,
    dispatch
  ),
  getMaterialClass: bindActionCreators(spillActions.getMaterialClass, dispatch),
});

SearchSpill.propTypes = {
  user: PropTypes.object.isRequired,
  spills: PropTypes.object.isRequired,
  admins: PropTypes.object.isRequired,
  success: PropTypes.bool.isRequired,
  searchSpills: PropTypes.func.isRequired,
  getOrganizationNames: PropTypes.func.isRequired,
  clientOrganizationNames: PropTypes.object.isRequired,
  agencies: PropTypes.func.isRequired,
  getAgencies: PropTypes.func.isRequired,
  getContractorsWithAddress: PropTypes.func.isRequired,
  contractors: PropTypes.func.isRequired,
  clearSpillsSearch: PropTypes.func.isRequired,
  getUsersForEmail: PropTypes.func.isRequired,
  packetReviewers: PropTypes.object.isRequired,
  getMaterialClass: PropTypes.func.isRequired,
  materialClass: PropTypes.object.isRequired
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchSpill)
);
