import { Constants, fileTypes, photoTypes } from ".";
import { ROLES, XLSX_DATATYPE } from "./keyMappers";
import publicIp from "public-ip";
import Moment from "moment";

export const roundOff = (val, decimalPlaces) => {
  return (
    Math.round(val * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces)
  );
};

export const getTypeOfAttachment = (attachmentName) => {
  const countRegex = /-[0-9]*\./g;
  for (let fileType of [...fileTypes, ...photoTypes]) {
    if (attachmentName.includes(fileType)) {
      let count = attachmentName.match(countRegex);
      count = +(count ? count[0].slice(1, count[0].length - 1) : 1);
      return { type: fileType, count: count ?? 1 };
    }
  }

  return null;
};

export const getAttachmentsTypeCount = (currentSpill) => {
  const spill = currentSpill?.data?.spill;
  const objToReturn = {};
  fileTypes.map((fileType) => (objToReturn[fileType] = 0));
  photoTypes.map((photoType) => (objToReturn[photoType] = 0));
  if (spill?.spill_notes?.length) {
    const notesAttachments = spill?.spill_notes
      ?.map((note) =>
        note.note_attachments && note.note_attachments.length
          ? note.note_attachments.map((note_attachment) => note_attachment.name)
          : []
      )
      .flat(10);
    notesAttachments.map((attachment) => {
      const fileTypeAndCount = getTypeOfAttachment(attachment);

      if (
        fileTypeAndCount?.type &&
        (objToReturn[fileTypeAndCount.type] ||
          objToReturn[fileTypeAndCount.type] === 0)
      ) {
        objToReturn[fileTypeAndCount.type] =
          objToReturn[fileTypeAndCount.type] > fileTypeAndCount.count
            ? objToReturn[fileTypeAndCount.type]
            : fileTypeAndCount.count;
      }
    });
  } else {
    photoTypes.map((type) => {
      objToReturn[type] = 0;
    });
  }

  return objToReturn;
};

export const removeProbPMFromOptions = (options) => {
  return options.filter((option) => option.role.role !== ROLES.PROB_PM);
};

export const getPublicIp = async (type) => {
  // return (await type) === "v6" ? publicIp.v6() : publicIp.v4();
  try {
    return type === 'v6' ? await publicIp.v6() : await publicIp.v4();
  } catch (error) {
    console.error('Error retrieving public IP address:', error);
    return null;
  }
};

export const getTimeDifference = (date1, date2) => {
  date1 = new Date(date1);
  date2 = new Date(date2);
  let seconds = Math.floor((date2 - date1) / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);

  hours = hours - days * 24;
  minutes = minutes - days * 24 * 60 - hours * 60;
  seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
  return { days, hours, minutes, seconds };
};

export const getServiceCreationTime = (spillObj, serviceName) => {
  let timeToReturn = [];
  spillObj.spill_notes.map(
    (note) =>
      note.service_type === serviceName && timeToReturn.push(note.created_at)
  );

  return timeToReturn[0] ?? null;
};

export const getTotalExcavationTime = (spillObj) => {
  const excavationBegunTime = getServiceCreationTime(
    spillObj,
    Constants.EXCAVATION_BEGUN_SERVICE_NAME
  );
  let totalTime = [];

  spillObj.spill_notes.filter(
    (note) =>
      note.service_type === Constants.EXCAVATION_COMPLETED_SERVICE_NAME &&
      note.excavation_time &&
      note.excavation_time !== "N/A" &&
      totalTime.push(note.excavation_time)
  );
  if (totalTime.length) return totalTime[0];
  const excavationCompletedTime = getServiceCreationTime(
    spillObj,
    Constants.EXCAVATION_COMPLETED_SERVICE_NAME
  );
  const absCurrentTime = new Date().toUTCString();
  const currentTime = excavationCompletedTime
    ? new Date(excavationCompletedTime).getTime() <
      new Date(excavationBegunTime).getTime()
      ? absCurrentTime
      : excavationCompletedTime
    : absCurrentTime;
  const totalDiff = getTimeDifference(excavationBegunTime, currentTime);
  return excavationBegunTime ? convertTimeToText(totalDiff) : "N/A";
};

export const convertTimeToText = (time) => {
  return (
    `${time.days ? `${time.days} d ` : ""}${time.hours ? `${time.hours} h ` : ""
    }${time.minutes ? `${time.minutes} m ` : ""}` || "N/A"
  );
};

export const convertServicesObj = (servicesObj) => {
  const arrToReturn = [];
  servicesObj.map((service) => {
    const alreadyPresent = arrToReturn.findIndex(
      (x) => x?.name === service?.category?.name
    );
    if (alreadyPresent >= 0) {
      arrToReturn[alreadyPresent].options.push({
        name: service?.name,
        id: service?.id,
        active: service?.active,
      });
    } else {
      arrToReturn.push({
        name: service?.category?.name,
        options: [
          { name: service?.name, id: service?.id, active: service?.active },
        ],
      });
    }
  });
  return orderServices(arrToReturn);
};

export const isObject = (obj) => {
  if (typeof obj === "object" && obj !== null && !Array.isArray(obj)) {
    return true;
  }
  return false;
};

export const isValidDate = function (date) {
  return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
};

export const compare = (x, y) => {
  if (isObject(x)) {
    if (isObject(y)) {
      if (!objCompare(x, y)) return false;
    } else {
      return false;
    }
  } else if (Array.isArray(x)) {
    if (Array.isArray(y)) {
      if (!arrCompare(x, y)) return false;
    } else {
      return false;
    }
  } else {
    if (x !== y) return false;
  }
  return true;
};

export const objCompare = function (o1, o2) {
  const o1Keys = typeof o1 === "object" && o1 !== null ? Object.keys(o1) : [];
  const o2Keys = typeof o2 === "object" && o2 !== null ? Object.keys(o2) : [];
  if (o1Keys.length !== o2Keys.length) return false;
  for (let i = 0; i < o1Keys.length; i++) {
    if (!o2Keys.includes(o1Keys[i])) {
      return false;
    }
    if (!compare(o1[o1Keys[i]], o2[o1Keys[i]])) return false;
  }
  return true;
};

export const arrCompare = function (a1, a2) {
  if (a1?.length !== a2?.length) return false;
  for (let i = 0; i < a1.length; i++) {
    if (!compare(a1[i], a2[i])) return false;
  }
  return true;
};

export const isContractorUser = (role) =>
  [ROLES.CONTRACTOR_ADMIN, ROLES.CONTRACTOR_USER].includes(role);

export const isCorporateUser = (role) =>
  [
    ROLES.CORPORATE_ACCOUNTING,
    ROLES.CORPORATE_ADMIN,
    ROLES.CORPORATE_LOGGER,
    ROLES.CORPORATE_USER,
  ].includes(role);

export const isPESUser = (role) =>
  [
    ROLES.PES_ADMIN,
    ROLES.PES_USER,
    ROLES.PROB_PM,
    ROLES.PES_ACCOUNTING_ADMIN,
    ROLES.SUPER_USER,
  ].includes(role);

export const isPESUser_2 = (role) =>
  [
    ROLES.PES_ADMIN,
    ROLES.PROB_PM,
    ROLES.SUPER_USER,
    ROLES.PES_ACCOUNTING_ADMIN,
  ].includes(role);

export const isPESUser_3 = (role) =>
  [
    ROLES.PES_ADMIN,
    ROLES.PES_USER,
    ROLES.PES_ACCOUNTING_ADMIN,
    ROLES.SUPER_USER,
  ].includes(role);

export const isPESUser_4 = (role) =>
  [
    ROLES.PES_ADMIN,
    ROLES.PES_ACCOUNTING_ADMIN,
    ROLES.SUPER_USER,
  ].includes(role);

export const isSUPERUSER = (role) => 
  [ROLES.SUPER_USER].includes(role);

export const isCorporateAdmin = (role) => [ROLES.CORPORATE_ADMIN].includes(role);

export const isBatchUpdatingUser = (role) =>
  [ROLES.PES_ACCOUNTING_ADMIN, ROLES.SUPER_USER].includes(role);

export const getAttachmentKey = (attachment) =>
  attachment?.key ||
  attachment?.url_link?.slice(
    attachment?.url_link?.indexOf("spills.attach") + "spills.attach".length + 1
  );

export const convertDateToLocal = (date) =>
  isValidDate(date) ? Moment(date)?.format("MMMM DD YYYY hh:mm a") : date;

export const getFormattedTime = (date) =>
  isValidDate(date)
    ? Moment.parseZone(date)?.format("MMMM DD YYYY hh:mm a")
    : date;
export const forceToCST = (date) => {
  if (!isValidDate(date) || !date) {
    return date;
  }
  const cstDate = new Date(date);
  const day = cstDate.getDate().toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const month = (cstDate.getMonth() + 1).toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const year = cstDate.getFullYear();
  const hours = cstDate.getHours().toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const minutes = cstDate.getMinutes().toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const seconds = cstDate.getSeconds().toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const toReturn = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
  return toReturn;
};
export const orderStatuses = (data) => {
  const FIXED_ORDER = [
    "Work In Progress",
    "Extended Remediation",
    "Pending Excavation",
    "Pending Disposal",
    "Site Work Complete",
    "Documentation In Review",
    "Documentation Sent Back to Contractor for Revision",
    "Ready to Invoice",
    "Final Review",
    "Ready to Close",
    "Invoice Submitted to Client",
    "Payment Pending",
    "Closed",
  ];

  return FIXED_ORDER.map((x) => data.find((y) => y.name === x));
};

const ContractorStatusVisibility = {
  "Open: Ready to Invoice": "Open: Documentation In Review",
  "Open: Final Review": "Open: Documentation In Review",
  "Open: Ready to Close": "Open: Documentation In Review",
};

const CorporateStatusVisibility = {
  "Open: Ready to Invoice": "Open: Documentation In Review",
  "Open: Final Review": "Open: Documentation In Review",
  "Open: Ready to Close": "Open: Documentation In Review",
  "Closed: Payment Pending": "Closed",
};

export const sortSpillReservesByDate = (data) => {
  return data?.sort(
    (a, b) => new Date(a?.created_at) - new Date(b?.created_at)
  );
};

export const statusVisibility = (spillStatus, role) => {
  if (isPESUser(role)) {
    return spillStatus;
  } else if (isContractorUser(role)) {
    return ContractorStatusVisibility[spillStatus] || spillStatus;
  } else {
    return CorporateStatusVisibility[spillStatus] || spillStatus;
  }
};

export const canSeeStampsOrAmounts = (role, visibility) => {
  if (isPESUser_2(role) || isPESUser(role)) {
    return true;
  }
  return visibility;
};

export const canSeeVoidOption = (role) => {
  if (isPESUser_3(role)) {
    return true;
  } else {
    return false;
  }
};

export const orderServices = (services) => {
  const orderedServicesList = {
    Response: [
      "Locate Contractor Services For Recovery Operations",
      "Dispatch Contractor For Corrective Actions Based on Site Assessment",
      "Contractor Arrived Onsite",
      "Project Review With Contractor Services",
      "Contractor Demobilizing For The Day",
      "Onsite Activities Completed",
      "SDS Library Search",
      "General Note/Log Entry",
    ],
    Regulatory: [
      "Regulatory Notification Completed To State Agency",
      "Regulatory Notification To Local Agency",
      "Regulatory Notification Completed To National Response Center",
      "Conversation With State Agency",
      "Conversation With Local Agency",
      "Conversation With National Response Center",
      "Completed & Submitted 5800.1 Form",
    ],
    Client: [
      "Request Shipping Documents From Client",
      "Client Logger Entry",
      "Advisory Review With Client",
    ],
    Excavation: [
      "Schedule Excavation",
      "Excavation Begun",
      "Excavation Completed",
    ],
    Waste: [
      "Waste Generated",
      "Review Waste Disposal Options",
      "Complete Generator's Waste Profile/Characterization As Agent For Client",
      "Schedule Waste Disposal",
      "Waste Disposal Completed",
      "Analytical Results",
    ],
    Admin: [
      "Status Letter To Contractor",
      "Closing Report To Client -- Administrative Support",
      "File/Attachment Download",
      "Deletion Of File Attachment",
      "Receipt and Review Of Contractor Invoices/Documents For Closure",
      "Letter Of Closure To State Agency",
      "Closure Packet For Payment",
      "Invoice Generation",
      "Contractor Payment/Check Generation",
      "Receipt of Assignment From Client",
      "Third Party Review(s)",
    ],
  };

  let finalResult = [];

  services?.map((category) => {
    const orderedList = orderedServicesList[category.name];
    const newList = [];
    orderedList?.map((service) => {
      const index = category.options.findIndex(
        (option) => option.name === service
      );
      if (index >= 0) {
        newList.push(category.options[index]);
        category.options.splice(index, 1);
      }
    });
    newList.push(...category.options);
    finalResult.push({ name: category.name, options: newList });
  });

  finalResult.map((result, index) => {
    const filteredReults = result?.options?.filter(
      (serviceOption) => serviceOption.active === true
    );
    result.options = filteredReults;
  });

  return finalResult;
};

export const getAgency = (agency) => {
  return agency?.name
    ? `${agency.name} (${agency.city}, ${agency.state})`
    : agency || "N/A";
};

export const renameAttachmentDuplicates = (data) => {
  let attachmentNameList = [];
  var count = {};

  if (data?.length > 1) {
    data?.map((item) => attachmentNameList.push(item?.file?.name));

    attachmentNameList?.forEach((x, i) => {
      if (attachmentNameList?.indexOf(x) !== i) {
        var occuranceCount =
          x in count ? (count[x] = count[x] + 1) : (count[x] = 1);

        var updatedOccuranceCount = occuranceCount + 1;

        const fileName = x?.split(x[x.lastIndexOf(".")])[0];
        const extension = x?.split(x[x.lastIndexOf(".")])[1];

        let updatedFileName =
          fileName + "(" + updatedOccuranceCount + ")" + "." + extension;

        while (attachmentNameList.indexOf(updatedFileName) !== -1) {
          updatedFileName =
            fileName + "(" + ++updatedOccuranceCount + ")" + "." + extension;
        }
        attachmentNameList[i] = updatedFileName;
      }
    });

    return attachmentNameList;
  } else {
    return data[0]?.file ? [data[0]?.file?.name] : data;
  }
};

export const renameContractorInvAttachmentsDuplicates = (data) => {
  let attachmentNameList = [];
  var count = {};

  if (data?.length > 1) {
    data?.map((item) => attachmentNameList.push(item));

    attachmentNameList?.forEach((x, i) => {
      if (attachmentNameList?.indexOf(x) !== i) {
        var occuranceCount =
          x in count ? (count[x] = count[x] + 1) : (count[x] = 1);

        var updatedOccuranceCount = occuranceCount + 1;

        const fileName = x?.split(x[x.lastIndexOf(".")])[0];
        const extension = x?.split(x[x.lastIndexOf(".")])[1];

        let updatedFileName =
          fileName + "(" + updatedOccuranceCount + ")" + "." + extension;

        while (attachmentNameList.indexOf(updatedFileName) !== -1) {
          updatedFileName =
            fileName + "(" + ++updatedOccuranceCount + ")" + "." + extension;
        }
        attachmentNameList[i] = updatedFileName;
      }
    });

    return attachmentNameList;
  } else {
    return data[0] ? [data[0]] : data;
  }
};

export const updatePageNumberBasedOnURL = (pageNumber, maxPageNumber) => {
  let pageNumberToReturn;

  if (parseInt(pageNumber) === 0 || parseInt(pageNumber?.split(".")[0]) === 0) {
    pageNumberToReturn = "1";
  } else if (parseInt(pageNumber) > 0 && parseInt(pageNumber) < maxPageNumber) {
    pageNumberToReturn = pageNumber;
  } else if (parseInt(pageNumber?.split(".")[0]) > 0) {
    pageNumberToReturn = pageNumber?.split(".")[0];
  } else if (
    parseInt(pageNumber) > maxPageNumber ||
    parseInt(pageNumber?.split(".")[0]) > maxPageNumber
  ) {
    pageNumberToReturn = JSON.stringify(maxPageNumber);
  }

  return pageNumberToReturn;
};

export const extractFileExtensionFromString = (str) => {
  const parts = str?.split(".");
  const extension = parts[parts?.length - 1];
  return extension;
};

export const getSearchResultTableHeaders = (
  downloadSubType,
  isSubrogationActive,
  currentUser,
  isPendingDisposalActive,
  isReport,
  isSubrogationReport,
  isRevenueAccrualReport
) => {
  const commonHeaders = [
    { title: "OPENED", type: XLSX_DATATYPE.DATE },
    { title: "CLOSED", type: XLSX_DATATYPE.DATE },
    { title: "JOB NO.", type: XLSX_DATATYPE.STRING },
    { title: "ER", type: XLSX_DATATYPE.STRING },
    { title: "CLAIM NO.", type: XLSX_DATATYPE.STRING },
    { title: "PROJECT MANAGER", type: XLSX_DATATYPE.STRING },
    { title: "CLIENT", type: XLSX_DATATYPE.STRING },
    { title: "POINT OF CONTACT", type: XLSX_DATATYPE.STRING },
    { title: "MATERIAL CLASS", type: XLSX_DATATYPE.STRING },
    { title: "MATERIAL", type: XLSX_DATATYPE.STRING },
    { title: "UN / NA NO.", type: XLSX_DATATYPE.STRING },
    { title: "AMOUNT RELEASED", type: XLSX_DATATYPE.NUMBER },
    { title: "QUANTITY TYPE RELEASED", type: XLSX_DATATYPE.STRING },
    { title: "LOCATION", type: XLSX_DATATYPE.STRING },
    { title: "LATITUDE", type: XLSX_DATATYPE.STRING },
    { title: "LONGITUDE", type: XLSX_DATATYPE.STRING },
    { title: "CONTRACTOR", type: XLSX_DATATYPE.STRING },
    { title: "CONTRACTOR LOCATION", type: XLSX_DATATYPE.STRING },
    { title: "RESERVE($)", type: XLSX_DATATYPE.NUMBER },
  ];  
  const updatedCommonHeaders = [...commonHeaders].filter(
    (header) => header.title !== "RESERVE($)"
  );

  if(isSubrogationReport) {
    return [
      ...commonHeaders,
      { title: "STATUS", type: XLSX_DATATYPE.STRING },
      { title: "DAMAGE CONTAINER TYPE", type: XLSX_DATATYPE.STRING },
      { title: "DAMAGE TYPE", type: XLSX_DATATYPE.STRING },
      { title: "PES INVOICE AMOUNT($)", type: XLSX_DATATYPE.NUMBER },
      { title: "INV NO", type: XLSX_DATATYPE.NUMBER },
      { title: "FINAL CONTRACTOR INVOICE AMOUNT($)", type: XLSX_DATATYPE.NUMBER },
    ]
  }

  if(isReport) {
    if (downloadSubType === "Admin Download") {
      return [
        ...commonHeaders,  
        { title: "STATUS", type: XLSX_DATATYPE.STRING },
        { title: "PES INVOICE AMOUNT($)", type: XLSX_DATATYPE.NUMBER },
        { title: "INV NO", type: XLSX_DATATYPE.NUMBER },
        { title: "FINAL CONTRACTOR INVOICE AMOUNT($)", type: XLSX_DATATYPE.NUMBER },
        { title: "TYPE", type: XLSX_DATATYPE.STRING },
        { title: "RESP TIME", type: XLSX_DATATYPE.STRING },
        { title: "PROJECTED ETA", type: XLSX_DATATYPE.STRING },
        { title: "DAYS OPENED", type: XLSX_DATATYPE.NUMBER },
      ];
    } else {
      return [
        ...commonHeaders,
        { title: "STATUS", type: XLSX_DATATYPE.STRING },
        { title: "DAMAGE CONTAINER TYPE", type: XLSX_DATATYPE.STRING },
        { title: "DAMAGE TYPE", type: XLSX_DATATYPE.STRING },
        { title: "PES INVOICE AMOUNT($)", type: XLSX_DATATYPE.NUMBER },
        { title: "INV NO", type: XLSX_DATATYPE.NUMBER },
        { title: "FINAL CONTRACTOR INVOICE AMOUNT($)", type: XLSX_DATATYPE.NUMBER },
      ];
    }
  }

  if (downloadSubType === "Admin Download") {
    return [
      ...commonHeaders.slice(0, -5),
      { title: "Facility Name", type: XLSX_DATATYPE.STRING },
      { title: "Facility Code", type: XLSX_DATATYPE.STRING },
      { title: "LATITUDE", type: XLSX_DATATYPE.STRING },
      { title: "LONGITUDE", type: XLSX_DATATYPE.STRING },
      { title: "CONTRACTOR", type: XLSX_DATATYPE.STRING },
      { title: "CONTRACTOR LOCATION", type: XLSX_DATATYPE.STRING },
      { title: "RESERVE($)", type: XLSX_DATATYPE.NUMBER },
      { title: "STATUS", type: XLSX_DATATYPE.STRING },
      { title: "PES INVOICE AMOUNT($)", type: XLSX_DATATYPE.NUMBER },
      { title: "INV NO", type: XLSX_DATATYPE.NUMBER },
      { title: "FINAL CONTRACTOR INVOICE AMOUNT($)", type: XLSX_DATATYPE.NUMBER },
      { title: "TYPE", type: XLSX_DATATYPE.STRING },
      { title: "RESP TIME", type: XLSX_DATATYPE.STRING },
      { title: "PROJECTED ETA", type: XLSX_DATATYPE.STRING },
      { title: "DAYS OPENED", type: XLSX_DATATYPE.NUMBER },
      { title: "DAYS TO CLOSE", type: XLSX_DATATYPE.NUMBER },
      { title: "DAYS TO PAY", type: XLSX_DATATYPE.NUMBER },
    ];
  }

  if(downloadSubType === "invoice_download_report") {
    return [
      ...commonHeaders,
      { title: "STATUS", type: XLSX_DATATYPE.STRING },
      { title: "PES INVOICE AMOUNT($)", type: XLSX_DATATYPE.NUMBER },
      { title: "INV NO", type: XLSX_DATATYPE.NUMBER },
      { title: "FINAL CONTRACTOR INVOICE AMOUNT($)", type: XLSX_DATATYPE.NUMBER },
      { title: "TYPE", type: XLSX_DATATYPE.STRING },
      { title: "RESP TIME", type: XLSX_DATATYPE.STRING },
      { title: "PROJECTED ETA", type: XLSX_DATATYPE.STRING },
      { title: "DAYS OPENED", type: XLSX_DATATYPE.NUMBER },
      { title: "DOWNLOADED BY", type: XLSX_DATATYPE.STRING },
      { title: "DATE DOWNLOADED", type: XLSX_DATATYPE.DATE },
      { title: "DAYS DOWNLOADED", type: XLSX_DATATYPE.NUMBER },
      { title: "DOWNLOADED FROM", type: XLSX_DATATYPE.STRING },
    ]
  }

  if(downloadSubType === "contractor_rejections_report") {
    return [
      ...commonHeaders,
      { title: "STATUS", type: XLSX_DATATYPE.STRING },
      { title: "PES INVOICE AMOUNT($)", type: XLSX_DATATYPE.NUMBER },
      { title: "INV NO", type: XLSX_DATATYPE.NUMBER },
      { title: "FINAL CONTRACTOR INVOICE AMOUNT($)", type: XLSX_DATATYPE.NUMBER },
      { title: "TYPE", type: XLSX_DATATYPE.STRING },
      { title: "RESP TIME", type: XLSX_DATATYPE.STRING },
      { title: "PROJECTED ETA", type: XLSX_DATATYPE.STRING },
      { title: "DAYS OPENED", type: XLSX_DATATYPE.NUMBER },
      { title: "CONTRACTOR REJECTED", type: XLSX_DATATYPE.STRING },
      { title: "CONTRACTOR REJECTED LOCATION", type: XLSX_DATATYPE.STRING },
      { title: "REJECTED BY", type: XLSX_DATATYPE.STRING },
      { title: "REASON FOR REJECTION", type: XLSX_DATATYPE.STRING },
    ]
  }

  if(downloadSubType === "shipper_report") {
    return [
      ...commonHeaders?.slice(0, -1),
      { title: "SHIPPER NAME", type: XLSX_DATATYPE.STRING },
      { title: "SHIPPER ADDRESS", type: XLSX_DATATYPE.STRING },
      { title: "RESERVE($)", type: XLSX_DATATYPE.NUMBER },
      { title: "STATUS", type: XLSX_DATATYPE.STRING },
      { title: "DAMAGE CONTAINER TYPE", type: XLSX_DATATYPE.STRING },
      { title: "DAMAGE TYPE", type: XLSX_DATATYPE.STRING },
      { title: "PES INVOICE AMOUNT($)", type: XLSX_DATATYPE.NUMBER },
      { title: "INV NO", type: XLSX_DATATYPE.NUMBER },
      { title: "FINAL CONTRACTOR INVOICE AMOUNT($)", type: XLSX_DATATYPE.NUMBER },
    ]
  }

  if(isRevenueAccrualReport && downloadSubType === "revenue_accrual_report") {
    return [
      { title: "OPENED", type: XLSX_DATATYPE.DATE },
      { title: "JOB NO.", type: XLSX_DATATYPE.STRING },
      { title: "CLIENT", type: XLSX_DATATYPE.STRING },
      { title: "ADDRESS", type: XLSX_DATATYPE.STRING },
      { title: "CITY", type: XLSX_DATATYPE.STRING },
      { title: "STATE", type: XLSX_DATATYPE.STRING },
      { title: "ZIP CODE", type: XLSX_DATATYPE.STRING },
      { title: "CONTRACTOR", type: XLSX_DATATYPE.STRING },
      { title: "CONTRACTOR LOCATION", type: XLSX_DATATYPE.STRING },
      { title: "STATUS", type: XLSX_DATATYPE.STRING },
      { title: "RESERVES($)", type: XLSX_DATATYPE.NUMBER },
      { title: "PES INVOICE AMOUNT($)", type: XLSX_DATATYPE.NUMBER },
      { title: "DAYS OPENED", type: XLSX_DATATYPE.NUMBER },
    ];
  };

  if (
    isSubrogationActive &&
    (currentUser?.data?.role?.role === ROLES.CONTRACTOR_ADMIN ||
      currentUser?.data?.role?.role === ROLES.CONTRACTOR_USER)
  ) {
    const returnData = [
      ...updatedCommonHeaders,
      { title: "STATUS", type: XLSX_DATATYPE.STRING },
      { title: "DAMAGE CONTAINER TYPE", type: XLSX_DATATYPE.STRING },
      { title: "DAMAGE TYPE", type: XLSX_DATATYPE.STRING },
      { title: "INV NO", type: XLSX_DATATYPE.NUMBER },
    ];
    return returnData
  }

  if (
    isSubrogationActive &&
    (currentUser?.data?.role?.role !== ROLES.CONTRACTOR_ADMIN ||
      currentUser?.data?.role?.role !== ROLES.CONTRACTOR_USER)
  ) {
    return [
      ...commonHeaders.slice(0,-5),
      { title: "Facility Name", type: XLSX_DATATYPE.STRING },
      { title: "Facility Code", type: XLSX_DATATYPE.STRING },
      { title: "LATITUDE", type: XLSX_DATATYPE.STRING },
      { title: "LONGITUDE", type: XLSX_DATATYPE.STRING },
      { title: "CONTRACTOR", type: XLSX_DATATYPE.STRING },
      { title: "CONTRACTOR LOCATION", type: XLSX_DATATYPE.STRING },
      { title: "RESERVE($)", type: XLSX_DATATYPE.NUMBER },
      { title: "STATUS", type: XLSX_DATATYPE.STRING },
      { title: "DAMAGE CONTAINER TYPE", type: XLSX_DATATYPE.STRING },
      { title: "DAMAGE TYPE", type: XLSX_DATATYPE.STRING },
      { title: "PES INVOICE AMOUNT($)", type: XLSX_DATATYPE.NUMBER },
      { title: "INV NO", type: XLSX_DATATYPE.NUMBER },
      { title: "FINAL CONTRACTOR INVOICE AMOUNT($)", type: XLSX_DATATYPE.NUMBER },
    ];
  }

  if (isPendingDisposalActive) {
    return [
      { title: "OPENED", type: XLSX_DATATYPE.DATE },
      { title: "JOB NO.", type: XLSX_DATATYPE.STRING },
      { title: "PROJECT MANAGER", type: XLSX_DATATYPE.STRING },
      { title: "CLIENT", type: XLSX_DATATYPE.STRING },
      { title: "MATERIAL CLASS", type: XLSX_DATATYPE.STRING }, 
      { title: "MATERIAL", type: XLSX_DATATYPE.STRING },
      { title: "UN / NA NO.", type: XLSX_DATATYPE.STRING },
      { title: "AMOUNT RELEASED", type: XLSX_DATATYPE.NUMBER },
      { title: "QUANTITY TYPE RELEASED", type: XLSX_DATATYPE.STRING },
      { title: "LOCATION", type: XLSX_DATATYPE.STRING },
      { title: "CONTRACTOR", type: XLSX_DATATYPE.STRING },
      { title: "CONTRACTOR LOCATION", type: XLSX_DATATYPE.STRING },
      { title: "SC#", type: XLSX_DATATYPE.NUMBER },
      { title: "TYPE", type: XLSX_DATATYPE.STRING },
      { title: "CONTENT", type: XLSX_DATATYPE.STRING },
      { title: "HAZ", type: XLSX_DATATYPE.STRING },
      { title: "CREATED", type: XLSX_DATATYPE.DATE },
      { title: "APPROX. DRUM WEIGHT", type: XLSX_DATATYPE.NUMBER },
      { title: "HANDLING DISPOSAL", type: XLSX_DATATYPE.STRING },
      { title: "CONTAINER POSSESSION", type: XLSX_DATATYPE.STRING },
      { title: "SCHEDULED DISPOSAL DATE", type: XLSX_DATATYPE.DATE },
    ];
  }

  if (currentUser?.data?.role?.role === ROLES.CONTRACTOR_ADMIN ||
    currentUser?.data?.role?.role === ROLES.CONTRACTOR_USER) {
    return [
      ...updatedCommonHeaders,
      { title: "STATUS", type: XLSX_DATATYPE.STRING },
      { title: "DAMAGE CONTAINER TYPE", type: XLSX_DATATYPE.STRING },
      { title: "DAMAGE TYPE", type: XLSX_DATATYPE.STRING },
      { title: "INV NO", type: XLSX_DATATYPE.NUMBER },
      { title: "FINAL CONTRACTOR INVOICE AMOUNT($)", type: XLSX_DATATYPE.NUMBER },
    ];

  } else {
    return [
      ...commonHeaders.slice(0,-5),
      { title: "Facility Name", type: XLSX_DATATYPE.STRING },
      { title: "Facility Code", type: XLSX_DATATYPE.STRING },
      { title: "LATITUDE", type: XLSX_DATATYPE.STRING },
      { title: "LONGITUDE", type: XLSX_DATATYPE.STRING },
      { title: "CONTRACTOR", type: XLSX_DATATYPE.STRING },
      { title: "CONTRACTOR LOCATION", type: XLSX_DATATYPE.STRING },
      { title: "RESERVE($)", type: XLSX_DATATYPE.NUMBER },
      { title: "STATUS", type: XLSX_DATATYPE.STRING },
      { title: "DAMAGE CONTAINER TYPE", type: XLSX_DATATYPE.STRING },
      { title: "DAMAGE TYPE", type: XLSX_DATATYPE.STRING },
      { title: "PES INVOICE AMOUNT($)", type: XLSX_DATATYPE.NUMBER },
      { title: "INV NO", type: XLSX_DATATYPE.NUMBER },
      { title: "FINAL CONTRACTOR INVOICE AMOUNT($)", type: XLSX_DATATYPE.NUMBER },
    ];
  }
};
